import axios from 'axios';

/**
 * Function to get a refresh token by calling the Go server endpoint.
 * @param {string} baseURL - The base URL of your server.
 * @param {string} username - The username of the user.
 * @param {string} passphrase - The passphrase for decryption.
 * @param {string} instaUserId - The Instagram user ID.
 * @returns {Promise<string>} - A promise that resolves to the access token.
 */
export async function getAccessToken(baseURL, username, passphrase, instaUserId) {
  try {
    // Make a GET request to the Go server endpoint
    const response = await axios.get(`${baseURL}`, {
      params: {
        username,
        passphrase,
        instaUserId
      }
    });

    // Check if the request was successful
    if (response.status === 200) {
      const { accessToken } = response.data;
      console.log('Access Token:', accessToken);
      return accessToken;
    } else {
      throw new Error('Failed to get access token');
    }
  } catch (error) {
    console.error('Error fetching access token:', error.message);
    throw error; // Propagate the error for further handling
  }
}
