import React, { useContext, useState } from "react";
import { DataContext } from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";

const EventCard = ({ event }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [eventData, setEventData] = useState(event);
  const navigate = useNavigate()

  const {
    categories,
    ageCategories,
    genderCategories,
    followerCountCategories,
    venues,
    loading,
  } = useContext(DataContext);
  if (loading) <></>;

  console.log(
    " venues",

    eventData.categories
  );

  // Handler to toggle edit mode
  const toggleEdit = () => {
    navigate("/event-form", { state: { event } });
  };

  // Handler to update event details
  const handleChange = (e) => {
    setEventData({ ...eventData, [e.target.name]: e.target.value });
  };

  return (
    <div className="bg-white shadow-md border rounded-lg overflow-hidden px-12 py-6 my-4  mb-8">
        <h2 className="text-2xl font-semibold my-4 mb-4 border-b-2 pb-5 text-blue-500">Event Detail</h2>
      <div className="mb-4 flex flex-wrap items-center justify-between pb-8 border-b-2 ">
        <p className="w-1/3">
          <strong>Event Name:</strong>{" "}
          {isEditing ? (
            <input
              type="text"
              name="eventName"
              value={eventData.eventName}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          ) : (
            eventData.eventName
          )}
        </p>
        <p className="w-1/3">
          <strong>Start Date:</strong>{" "}
          {isEditing ? (
            <input
              type="date"
              name="startDate"
              value={eventData.startDate}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          ) : (
            // Format the date when not editing
            new Date(eventData.startDate).toLocaleDateString("en-GB") // 'en-GB' for 'dd/mm/yyyy' format
          )}
        </p>
        <p className="w-1/3">
          <strong>End Date:</strong>{" "}
          {isEditing ? (
            <input
              type="date"
              name="endDate"
              value={eventData.endDate}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          ) : (
            new Date(eventData.endDate).toLocaleDateString("en-GB")
          )}
        </p>
        <p className="w-1/3 mt-8">
          <strong>Venue:</strong>{" "}
          {isEditing ? (
            <input
              type="text"
              name="venue"
              value={eventData.venue}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          ) : venues.find((venue) => venue.id == eventData.venue) ? (
            Object.entries(venues.find((venue) => venue.id == eventData.venue))
              .filter(([key]) => key !== "id") // Exclude the 'id' property
              .map(([, value]) => value) // Get only the values
              .join(", ")
          ) : (
            "eventData.venue"
          )}
        </p>
      </div>
      <h2 className="text-2xl font-semibold my-4 mb-4 border-b-2 pb-5 text-blue-500">Category Detail</h2>
      <div className="mb-4 flex flex-wrap items-center justify-between pb-8 border-b-2">
      <p className="w-1/3 mt-8">
          <strong>Generic:</strong>{" "}
          {isEditing ? (
            <input
              type="text"
              name="age"
              value={eventData.categories}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          ) : eventData.categories ? (
            categories
              .filter((category) => eventData.categories.includes(category.id)) // Filter categories based on ids in eventData.category
              .map((category) => category.name) // Map to the names of the filtered categories
              .join(", ")
          ) : (
            ""
          )}
        </p>
        <p className="w-1/3 mt-8">
          <strong>Age:</strong>{" "}
          {isEditing ? (
            <input
              type="text"
              name="age"
              value={eventData.category}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          ) : eventData.ageCategories ? (
            ageCategories
              .filter((category) =>
                eventData.ageCategories.includes(category.id)
              ) // Filter categories based on ids in eventData.category
              .map((category) => category.name) // Map to the names of the filtered categories
              .join(", ")
          ) : (
            ""
          )}
        </p>
        <p className="w-1/3 mt-8">
          <strong>Gender:</strong>{" "}
          {isEditing ? (
            <input
              type="text"
              name="gender"
              value={eventData.category}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          ) : eventData.genderCategories ? (
            genderCategories
              .filter((category) =>
                eventData.genderCategories.includes(category.id)
              ) // Filter categories based on ids in eventData.category
              .map((category) => category.gender) // Map to the names of the filtered categories
              .join(", ")
          ) : (
            ""
          )}
        </p>
        <p className="w-1/3 mt-8">
          <strong>Followers:</strong>{" "}
          {isEditing ? (
            <input
              type="text"
              name="followers"
              value={eventData.category}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          ) : eventData.followerCountCategories ? (
            followerCountCategories
              .filter((category) =>
                eventData.followerCountCategories.includes(category.id)
              ) // Filter categories based on ids in eventData
              .map((category) => `${category.startCount}-${category.endCount}`) // Map to hyphen-separated startCount and endCount
              .join(", ")
          ) : (
            ""
          )}
        </p>
      </div>
      <h2 className="text-2xl font-semibold my-4 mb-4 border-b-2 pb-5 text-blue-500">Sponsors Detail</h2>
      {  eventData.sponsorData && event.sponsorData.map(x => (
        <div className="w-full flex flex-col items-start space-y-4 pb-4 border-b-2">
           
            <div className="w-full mb-2 flex flex-wrap items-center justify-between ">
            <p className="w-1/3 mt-8">
                <strong>Sponsor Name:</strong>{" "}
                {isEditing ? (
                <input
                    type="text"
                    name="sponsorName"
                    value={x.
                        sponsorCategory}
                    onChange={handleChange}
                    className="border rounded px-2 py-1"
                />
                ) : (
                x.
                sponsorCategory
                )}
            </p>
            <p className="w-1/3 mt-8">
                <strong>Coupons:</strong>{" "}
                {isEditing ? (
                <input
                    type="number"
                    name="coupons"
                    value={x.noOfCoupons}
                    onChange={handleChange}
                    className="border rounded px-2 py-1"
                />
                ) : (
                    x.noOfCoupons
                )}
            </p>
            <p className="w-1/3 mt-8">
                <strong>Gift Hampers:</strong>{" "}
                {isEditing ? (
                <input
                    type="number"
                    name="giftHampers"
                    value={x.noOfGiftHampers}
                    onChange={handleChange}
                    className="border rounded px-2 py-1"
                />
                ) : (
                    x.noOfGiftHampers
                )}
            </p>
            <p className="w-1/3 mt-8">
                <strong>Price:</strong>{" "}
                {isEditing ? (
                <input
                    type="number"
                    name="price"
                    value={x.price}
                    onChange={handleChange}
                    className="border rounded px-2 py-1"
                />
                ) : (
                x.price
                )}
            </p>
            </div>
        </div>
    ))}

      <div className="mb-4 flex flex-col items-start justify-between pt-8 border-b-2">
        <h2 className="text-2xl font-semibold mb-4 text-blue-500">Template Image</h2>
        {eventData.templateData ? (
          <img
            src={eventData.templateData}
            alt="Template"
            className="w-auto h-60 object-cover rounded-md"
          />
        ) : (
          <p>No Image Available</p>
        )}
      </div>

      <button
        onClick={toggleEdit}
        className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
      >
        {isEditing ? "Save" : "Edit"}
      </button>
    </div>
  );
};

export default EventCard;
