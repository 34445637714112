import React from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa"; // Importing arrow icons from react-icons

const data = [
  { label: "Total Participant", number: "85", percentage: 60 },
  { label: "This month", number: "85", percentage: -20 },
  { label: "Active", number: "120", percentage: 90 },
  { label: "Total Participant", number: "85", percentage: -15 },
  { label: "This month", number: "85", percentage: 60 },
  { label: "Active", number: "120", percentage: 90 },
];

const TotalStatEvent = () => {
  return (
    <div className="mt-28 flex flex-wrap justify-between ">
      {data.map((item, index) => (
        <div
          key={index}
          className="flex w-[28%] items-center text-left mt-5 border bg-white shadow-lg rounded-lg p-4 hover:bg-blue-100 transition-all duration-300"
        >
          <div className="flex items-center justify-center w-16 h-16 border-4 border-gray-700 rounded-full overflow-hidden mr-4">
            {/* Conditional rendering for arrow icons */}
            {item.percentage >= 0 ? (
              <FaArrowUp className="text-green-500 text-2xl" /> // Up arrow for increase
            ) : (
              <FaArrowDown className="text-red-500 text-2xl" /> // Down arrow for decrease
            )}
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-gray-500 text-sm">{item.label}</div>
            <div className="text-lg font-semibold text-gray-800">{item.number}</div>
            <div
              className={`text-sm ${
                item.percentage >= 0 ? "text-green-500" : "text-red-500"
              }`}
            >
              {item.percentage >= 0 ? `${item.percentage}% Increase` : `${Math.abs(item.percentage)}% Decrease`}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TotalStatEvent;
