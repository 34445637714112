import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import styles from "./DonationTable.module.css";
import { donationData } from "../donationData";

const DonationTable = () => {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All"); // Filter state
  const eventsPerPage = 4; // Number of events to display per page

  const navigate = useNavigate(); // Initialize navigate

  // Pagination logic
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = donationData
    .filter((event) => (filter === "All" ? true : event.category === filter))
    .slice(indexOfFirstEvent, indexOfLastEvent);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle filter change
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  return (
    <>
      <div className={styles.filterCreateContainer}>
        <div>
          {/* <label htmlFor="venue">Select Venue</label> */}

          <select
            className={styles.filterButton}
            value={filter}
            onChange={handleFilterChange}
          >
            {/* Filters */}
            {/* <div className={styles.optionContainer}> */}
            <option value="ongoin">Ongoing Events</option>
            <option value="week">Past week Events</option>
            <option value="month">Past month Events</option>
            <option value="year">Past year Events</option>
            {/* </div> */}
          </select>
          <button
            className={styles.createButton}
            onClick={() => navigate("/donation-settings-form")} // Navigate to EventForm
          >
            Payment Settings
          </button>
        </div>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search..."
            className={styles.searchInput}
          />
        </div>
      </div>
      <div>
        {/* Events table */}
        <div className={styles.eventsTable}>
          <table className={styles.tableEvent}>
            <thead>
              <tr className={styles.eventTr}>
                <th>Doner Name</th>
                <th>Category</th>
                <th>Amount</th>
                <th>Event Name</th>
                <th>Payment Mode</th>
                <th>Transection ID</th>
              </tr>
            </thead>
            <tbody>
              {currentEvents.map((doner) => (
                <tr key={doner.id}>
                  <td>{doner.donerName}</td>
                  <td>{doner.category}</td>
                  <td>{doner.amount}</td>
                  <td>{doner.eventName}</td>
                  <td>{doner.paymentMode}</td>
                  <td>{doner.transactionId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className={styles.pagination}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.paginationButton}
          >
            Prev
          </button>
          <p className={styles.pageText}>
            Page {currentPage}/{Math.ceil(donationData.length / eventsPerPage)}
          </p>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastEvent >= donationData.length}
            className={styles.paginationButton}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default DonationTable;
