import React from "react";
import styles from "./TotalDonationStat.module.css";
import arrowUp from "../uparrow.png";

const data = [
  {
    icon: arrowUp,
    label: "Total Donations Received",
    number: "85",
    percentage: 60,
  },
  { icon: arrowUp, label: "Number Of Donors", number: "85", percentage: 60 },
  { icon: arrowUp, label: "Number Of Events", number: "120", percentage: 90 },
];

const TotalDonationStat = () => {
  return (
    <div className={styles.statContainer}>
      {data.map((item, index) => (
        <div key={index} className={styles.totalStat}>
          <div className={styles.iconContainer}>
            <img src={item.icon} alt="Icon" className={styles.icon} />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.label}>{item.label}</div>
            <div className={styles.number}>{item.number}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TotalDonationStat;
