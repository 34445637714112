import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { DataContext } from "../../context/DataContext";
import backIcon from "../category/AddCategory/backIcon.png"; // Assuming you have a back icon

// Hashtag Form Component
const HashtagForm = ({ onAddHashtag }) => {
  const { eventData } = useContext(DataContext);
  const [formData, setFormData] = useState({ hashtagName: "", eventId: "" });
  const [isAdding, setIsAdding] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    const apiDomain = process.env.REACT_APP_API_DOMAIN;
    const accessToken = Cookies.get("fbauthToken");

    try {
      await axios.post(`${apiDomain}/api/v2/createhashtag?access_token=${accessToken}`, {
        hashtag_name: formData.hashtagName,
        event_id: parseInt(formData.eventId),
      });
      alert("Hashtag added successfully");
      onAddHashtag();
      setFormData({ hashtagName: "", eventId: "" });
    } catch (error) {
      console.error("Error adding hashtag:", error);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-6">
      <h2 className="text-2xl font-bold mb-4">Create New Hashtag</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="hashtagName" className="block text-gray-700 font-semibold">
              Hashtag Name
            </label>
            <input
              type="text"
              id="hashtagName"
              name="hashtagName"
              value={formData.hashtagName}
              onChange={handleChange}
              required
              placeholder="Enter Hashtag"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label htmlFor="eventId" className="block text-gray-700 font-semibold">
              Event
            </label>
            <select
              id="eventId"
              name="eventId"
              value={formData.eventId}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="" disabled>
                Select an Event
              </option>
              {eventData && eventData.map((event) => (
                <option key={event.eventID} value={event.eventID}>
                  {event.eventName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          type="submit"
          className={`w-full py-2 bg-blue-500 text-white font-semibold rounded-md ${
            isAdding ? "cursor-not-allowed" : "hover:bg-blue-600"
          }`}
          disabled={isAdding}
        >
          {isAdding ? "Adding..." : "Add Hashtag"}
        </button>
      </form>
    </div>
  );
};


// Hashtag List Component
const HashtagList = ({ hashtags, onDelete }) => {
  if (!hashtags || hashtags.length === 0) {
    return <p className="text-gray-600">No hashtags available</p>;
  }

  return (
    <div className="space-y-4">
      {hashtags.map((hashtag) => (
        <div key={hashtag.id} className="bg-white p-4 shadow-md rounded-lg">
          <h3 className="text-xl font-bold mb-2">{hashtag.eventName}</h3>
          <div className="flex flex-wrap gap-2">
            {hashtag.tags && hashtag.tags.length > 0 ? (
              hashtag.tags.map((tag, index) => (
                <div
                  key={index}
                  className="flex items-center bg-gray-200 p-2 rounded-md space-x-2"
                >
                  <span className="text-gray-700">#{tag}</span>
                  <button
                    className="text-red-500 hover:text-red-600"
                    onClick={() => onDelete(tag, hashtag.id)}
                  >
                    Delete
                  </button>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No tags for this event</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

// Main Event Hashtag Manager Component
const EventHashtagManager = () => {
  const { eventData, loading } = useContext(DataContext);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [hashtags, setHashtags] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHashtags = async () => {
      if (!eventData || loading) return;

      try {
        const hashtagData = [];
        for (const event of eventData) {
          const accessToken = Cookies.get("fbauthToken");
          const response = await axios.get(
            `${apiDomain}/api/v2/gethashtagsbyevent?event_id=${event.eventID}&access_token=${accessToken}`
          );
          hashtagData.push({ id: event.eventID, eventName: event.eventName, tags: response.data });
        }
        setHashtags(hashtagData);
      } catch (error) {
        console.error("Error fetching hashtags:", error);
      }
    };

    fetchHashtags();
  }, [loading, eventData]);

  const handleDelete = async (hashtagName, eventId) => {
    setIsDeleting(true);
    const accessToken = Cookies.get("fbauthToken");

    try {
      await axios.delete(
        `${apiDomain}/api/v2/deletehashtag?access_token=${accessToken}&hashtag_name=${encodeURIComponent(hashtagName)}&event_id=${encodeURIComponent(eventId)}`
      );
      alert("Hashtag deleted successfully");
      refreshHashtags();
    } catch (error) {
      console.error("Error deleting hashtag:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const refreshHashtags = async () => {
    try {
      const hashtagData = [];
      for (const event of eventData) {
        const accessToken = Cookies.get("fbauthToken");
        const response = await axios.get(
          `${apiDomain}/api/v2/gethashtagsbyevent?event_id=${event.eventID}&access_token=${accessToken}`
        );
        hashtagData.push({ id: event.eventID, eventName: event.eventName, tags: response.data });
      }
      setHashtags(hashtagData);
    } catch (error) {
      console.error("Error refreshing hashtags:", error);
    }
  };

  return (
    <div className="p-8 max-w-5xl mx-auto">
      {/* Back Button */}
      {/* <div className="flex items-center mb-6">
        <button
          type="button"
          className="mr-4"
          onClick={() => navigate("/")}
        >
          <img src={backIcon} alt="Back" className="w-6" />
        </button>
        <h1 className="text-3xl font-bold">Event Hashtag Manager</h1>
      </div> */}

      {/* Hashtag Form */}
      <HashtagForm onAddHashtag={refreshHashtags} />

      {/* List of Hashtags */}
      <HashtagList hashtags={hashtags} onDelete={handleDelete} />
    </div>
  );
};

export default EventHashtagManager;
