import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../category/CategoryTable/CategoryTable.module.css";
import axios from "axios";
import Cookies from 'js-cookie';
import { DataContext } from "../../context/DataContext";

const VenueTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const venuesPerPage = 4;
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const navigate = useNavigate();

  const { venues, loading } = useContext(DataContext);
  const [editingId, setEditingId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    address: "",
    city: "",
    state: "",
    pin_code: "",
    country: "",
  });

  if (loading) return <p>Loading...</p>;

  const indexOfLastVenue = currentPage * venuesPerPage;
  const indexOfFirstVenue = indexOfLastVenue - venuesPerPage;
  const currentVenues = venues.slice(indexOfFirstVenue, indexOfLastVenue);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deleteVenue = async (id) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      await axios.delete(`${apiDomain}/api/v2/deletevenues?id=${id}&access_token=${accessToken}`);
      alert('Venue deleted successfully');
      window.location.reload();
    } catch (error) {
      alert(`Error: ${error.response?.data || error.message}`);
    }
  };

  const handleEditClick = (venue) => {
    setEditingId(venue.id);
    setEditFormData({
      address: venue.address,
      city: venue.city,
      state: venue.state,
      pin_code: venue.pin_code,
      country: venue.country,
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleSaveClick = async (id) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      await axios.put(`${apiDomain}/api/v2/updatevenues?id=${id}&access_token=${accessToken}`, editFormData);
      alert('Venue updated successfully');
      setEditingId(null);
      window.location.reload();
    } catch (error) {
      alert(`Failed to update venue: ${error.message}`);
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };

  return (
    <div className="container mx-auto px-4">
      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-800 text-white">
            <tr>
              {["Address", "City", "State", "Pin Code", "Country", "Action"].map((header) => (
                <th
                  key={header}
                  className="w-1/6 text-left py-3 px-4 uppercase font-semibold text-sm"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {currentVenues.map((venue) => (
              <tr key={venue.id} className="hover:bg-gray-100">
                <td className="text-left py-3 px-4">
                  {editingId === venue.id ? (
                    <input
                      type="text"
                      name="address"
                      value={editFormData.address}
                      onChange={handleEditChange}
                      className="border rounded-md p-1"
                    />
                  ) : (
                    venue.address
                  )}
                </td>
                <td className="text-left py-3 px-4">
                  {editingId === venue.id ? (
                    <input
                      type="text"
                      name="city"
                      value={editFormData.city}
                      onChange={handleEditChange}
                      className="border rounded-md p-1"
                    />
                  ) : (
                    venue.city
                  )}
                </td>
                <td className="text-left py-3 px-4">
                  {editingId === venue.id ? (
                    <input
                      type="text"
                      name="state"
                      value={editFormData.state}
                      onChange={handleEditChange}
                      className="border rounded-md p-1"
                    />
                  ) : (
                    venue.state
                  )}
                </td>
                <td className="text-left py-3 px-4">
                  {editingId === venue.id ? (
                    <input
                      type="text"
                      name="pin_code"
                      value={editFormData.pin_code}
                      onChange={handleEditChange}
                      className="border rounded-md p-1"
                    />
                  ) : (
                    venue.pin_code
                  )}
                </td>
                <td className="text-left py-3 px-4">
                  {editingId === venue.id ? (
                    <input
                      type="text"
                      name="country"
                      value={editFormData.country}
                      onChange={handleEditChange}
                      className="border rounded-md p-1"
                    />
                  ) : (
                    venue.country
                  )}
                </td>
                <td className="text-left py-3 px-4">
                  {editingId === venue.id ? (
                    <>
                      <button
                        onClick={() => handleSaveClick(venue.id)}
                        className="bg-green-500 hover:bg-green-700 text-white py-1 px-2 rounded"
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancelClick}
                        className="bg-gray-500 hover:bg-gray-700 text-white py-1 px-2 rounded ml-2"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEditClick(venue)}
                        className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => deleteVenue(venue.id)}
                        className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded ml-2"
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center mt-12 bg-gray-200">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ${currentPage === 1 && "opacity-50 cursor-not-allowed"}`}
          >
            Prev
          </button>
          <span>
            Page {currentPage} of {Math.ceil(venues.length / venuesPerPage)}
          </span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastVenue >= venues.length}
            className={`bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ${indexOfLastVenue >= venues.length && "opacity-50 cursor-not-allowed"}`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default VenueTable;
