import React from "react";
import TotalDonationStat from "./totalDonationStat/TotalDonationStat";
import DonationTable from "./donationTable/DonationTable";

const Donation = () => {
  return (
    <div>
      <TotalDonationStat />
      <DonationTable />
    </div>
  );
};

export default Donation;
