import React from "react";
import ParticipantProfile from "./ParticipantProfile";
import ParticipantOngoingTable from "./ParticipantOngoingTable";
import ParticipantParticipatedTable from "./ParticipantParticipatedTable";

const ParticipantDetails = () => {
  return (
    <div>
      <ParticipantProfile />
      <ParticipantOngoingTable />
      <ParticipantParticipatedTable />
    </div>
  );
};

export default ParticipantDetails;
