import React from "react";
import profileImage from "./profile.jpg";
import dropdown from "./dropdown.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  // Function to simulate Facebook login status
  // const isFbLogin = () => {
  //   // Logic to determine if user is logged in via Facebook
  //   // For example purposes, we'll just return the value directly
  //   if (localStorage["facebookConnected"] === "true") return true;

  //   return false; // Change this to false to simulate a logged-out state
  // };

  return (

      <nav className="fixed top-0 flex justify-between items-center p-6 bg-[#fff]  shadow-md left-[18vw] w-[82%] z-50">
        <div className="flex items-center">
          <span className="text-black font-bold text-2xl leading-[91.5%]">John Wick</span>
          <span role="img" aria-label="Wave" className="text-2xl ml-2">
            👋
          </span>
        </div>
        <div className="flex items-center gap-6">
          {/* {isFbLogin() ? (
            <> */}
          <span className="text-2xl">🔔</span>
          <div className="relative flex items-center gap-2.5">
            <div
              className="w-12 h-12 rounded-full bg-cover bg-center"
              style={{ backgroundImage: `url(${profileImage})` }}
            ></div>
            <button className="ml-2 p-2 bg-white border-none rounded-md cursor-pointer">
              <img src={dropdown} alt="Dropdown" />
            </button>
          </div>
          {/* </>
          ) : (
            <button className="bg-green-400 text-white px-4 py-2 rounded-md font-medium">
              <Link to='https://dafukcrew.com/auth/facebook?redirectTo=https://namasvi.indicarena.com'>
                Connect
              </Link>
            </button>
          )} */}
        </div>
      </nav>
    // </div>
  );
};

export default Navbar;
