import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./EditOnboardingQuestion.module.css";
import backIcon from "./backIcon.png";

const EditOnboardingQuestion = () => {
  const navigate = useNavigate();

  // Initial form data
  const [formData, setFormData] = useState({
    location: "location1",
    question1: "Sample question 1",
    choices: ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
  });

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChoiceChange = (index, value) => {
    const updatedChoices = [...formData.choices];
    updatedChoices[index] = value;
    setFormData({
      ...formData,
      choices: updatedChoices,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Updated form data:", formData);
    // Navigate to the same page to simulate form submission
    navigate("/questionnaire");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button
          type="button"
          className={styles.backButton}
          onClick={() => navigate("/")}
        >
          <img src={backIcon} alt="Back Icon" />
        </button>
        <h2 className={styles.title}>Add Questionnaire</h2>
      </div>
      <form className={styles.eventForm} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label}>Selection Location:</label>
          <select
            name="location"
            className={styles.select}
            value={formData.location}
            onChange={handleInputChange}
          >
            <option value="location1">Location 1</option>
            <option value="location2">Location 2</option>
            <option value="location3">Location 3</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Question 1:</label>
          <input
            type="text"
            name="question1"
            className={styles.input}
            value={formData.question1}
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.formGroupChoices}>
          <label className={styles.label}>Choices:</label>
          <div className={styles.choices}>
            {formData.choices.map((choice, index) => (
              <input
                key={index}
                type="text"
                className={styles.input}
                value={choice}
                onChange={(e) => handleChoiceChange(index, e.target.value)}
              />
            ))}
          </div>
        </div>

        <button type="submit" className={styles.viewButton}>
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditOnboardingQuestion;
