import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./EditRole.module.css";
import backIcon from "../backIcon.png";
import editIcon from "../backIcon.png"; // Assuming you have an edit icon image

const EditRole = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [roleName, setRoleName] = useState("Admin"); // Initial role name

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveRole = () => {
    // Handle saving role logic here
    console.log("Saving role:", roleName);
    setIsEditing(false); // Exit editing mode after saving
  };

  return (
    <div className={styles.container}>
      <form className={styles.eventForm}>
        <div className={styles.header}>
          <button
            type="button"
            className={styles.backButton}
            onClick={() => navigate("/")}
          >
            <img src={backIcon} alt="backIcon" />
          </button>
          <h2 className={styles.title}>Create New Role</h2>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="role">Role Name</label>
            <div className={styles.roleInputContainer}>
              {isEditing ? (
                <>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                  <button
                    type="button"
                    className={styles.editButton}
                    onClick={handleSaveRole}
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <span>{roleName}</span>
                  <button
                    type="button"
                    className={styles.editButton}
                    onClick={handleEditClick}
                  >
                    <img src={editIcon} alt="editIcon" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="loc">Location</label>
            <select id="loc" name="loc">
              <option value="loc1">Location 1</option>
              <option value="loc2">Location 2</option>
              <option value="loc3">Location 3</option>
            </select>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="permissions">Permissions</label>
            <div className={styles.permissions}>
              <label>
                <input type="checkbox" name="permission1" /> Manage Users
              </label>
              <label>
                <input type="checkbox" name="permission2" /> Create Events
              </label>
              <label>
                <input type="checkbox" name="permission3" /> Edit Events
              </label>
              <label>
                <input type="checkbox" name="permission4" /> Delete Events
              </label>
              <label>
                <input type="checkbox" name="permission5" />
                View Donations
              </label>
              <label>
                <input type="checkbox" name="permission6" /> Generate Reports
              </label>
              <label>
                <input type="checkbox" name="permission7" />
                Send Notifications
              </label>
              <label>
                <input type="checkbox" name="permission8" /> Manage Settings
              </label>
            </div>
          </div>
        </div>

        <button
          type="button"
          className={styles.viewButton}
          onClick={() => navigate("")}
        >
          Save Role
        </button>
      </form>
    </div>
  );
};

export default EditRole;
