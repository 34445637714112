// src/context/DataContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [participantsMonthly, setParticipantsMonthly] = useState(null);
  const [participantsTotal, setParticipantsTotal] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [categories, setGenericCategories] = useState(null);
  const [ageCategories, setAgeCategories] = useState(null);
  const [genderCategories, setGenderCategories] = useState(null);
  const [followerCountCategories, setFollowerCountCategories] = useState(null);
  const [venues, setVenues] = useState(null);
  const [sponsors, setSponsors] = useState(null);
  const [audienceVotingWeights, setAudienceVotingWeights] = useState(null);
  const [sponsorVotingWeights, setSponsorVotingWeights] = useState(null);
  // const [sponsors, setSponsors] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = Cookies.get("fbauthToken"); // Get the fbauthToken from cookies
        if (accessToken) {
          // API Calls
          const [
            eventsRes, 
            categories,
            ageCategoriesRes,
            genderCategoriesRes,
            followerCountCategoriesRes,
            venuesRes,
            participantsMonthlyRes,
            participantsTotalRes,
            audienceVotingRes,
            sponsorVotingRes,
            participantRes,
            sponsorsRes,
          ] = await Promise.all([
           axios.get(`${apiDomain}/api/v2/getevent?pageSize=100`, { params: { access_token: accessToken } }),
            axios.get(`${apiDomain}/api/v2/category`, { params: { access_token: accessToken, categoryType: 'generic' } }),
            axios.get(`${apiDomain}/api/v2/category`, { params: { access_token: accessToken, categoryType: 'age' } }),
            axios.get(`${apiDomain}/api/v2/category`, { params: { access_token: accessToken, categoryType: 'gender' } }),
            axios.get(`${apiDomain}/api/v2/category`, { params: { access_token: accessToken, categoryType: 'followercount' } }),
            axios.get(`${apiDomain}/api/v2/venues`, { params: { access_token: accessToken } }),
            axios.get(`${apiDomain}/api/v2/monthlyparticipants`, { params: { access_token: accessToken } }),
            axios.get(`${apiDomain}/api/v2/totalparticipants`, { params: { access_token: accessToken } }),
            axios.get(`${apiDomain}/api/v2/votingweight?type=audience`, { params: { access_token: accessToken } }),
            axios.get(`${apiDomain}/api/v2/votingweight?type=sponsor`, { params: { access_token: accessToken } }),
            axios.get(`${apiDomain}/api/v2/exploreparticipants`, { params: { access_token: accessToken } }),
            axios.get(`${apiDomain}/api/v2/getallsponsors`, { params: { access_token: accessToken } }),

            // axios.get(`${apiDomain}/api/v2/getsponsors`, { params: { access_token: accessToken } }),
          ]);

          // Set States
          setEventData(eventsRes.data);
          setGenericCategories(categories.data);
          setAgeCategories(ageCategoriesRes.data);
          setGenderCategories(genderCategoriesRes.data);
          setFollowerCountCategories(followerCountCategoriesRes.data);
          setVenues(venuesRes.data);
          setParticipant(participantRes.data)
          setParticipantsMonthly(participantsMonthlyRes.data);
          setParticipantsTotal(participantsTotalRes.data);
          setAudienceVotingWeights(audienceVotingRes.data);
          setSponsorVotingWeights(sponsorVotingRes.data);
          setSponsors(sponsorsRes.data)
          // setSponsors(sponsorsRes.data);

          // Log data (for debugging)
          console.log("eventsRes", eventsRes)
          console.log("genericCategories", categories.data);
          console.log("ageCategories", ageCategoriesRes.data);
          console.log("genderCategories", genderCategoriesRes.data);
          console.log("followerCountCategories", followerCountCategoriesRes.data);
          console.log("venueData", venuesRes.data);
          console.log('participants res', participantRes)
          console.log("participantsTotal", participantsTotalRes.data);
          console.log('audienceVotingData', audienceVotingRes.data);
          console.log('sponsorvotingData', sponsorVotingRes.data);
          console.log('Sponsor',  sponsorsRes.data)
          //console.log("sponsors", sponsorsRes.data);

        } else {
          console.error("No access token found in cookies");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Fetch data on component mount
  }, [apiDomain]); // Added apiDomain as a dependency, adjust if necessary

  return (
    <DataContext.Provider
      value={{
        eventData,
        categories,
        ageCategories,
        genderCategories,
        followerCountCategories,
        venues,
        audienceVotingWeights,
        sponsorVotingWeights,
        participant,
        participantsMonthly,
        participantsTotal,
        sponsors,
        loading,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
