import React, { useState } from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa"; // Importing arrow icons
import arrowUp from "./uparrow.png";

const TotalStat = () => {
  const [data, setData] = useState([
    { label: "Total Participants", number: "1", percentage: 60 },
    { label: "Monthly Participants", number: "85", percentage: -20 },
    { label: "Achievements", number: "120", percentage: 90 },
  ]);

  return (
    <div className="flex flex-col mt-28 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4">
        {data.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg p-5 flex items-center transition-transform duration-300 transform hover:scale-105"
          >
            {/* Icon/Graph Indicator */}
            <div className="relative w-16 h-16 flex justify-center items-center border-4 border-black rounded-full mr-4">
              {item.percentage >= 0 ? (
                <FaArrowUp className="text-green-500 text-3xl" /> // Up arrow for increase
              ) : (
                <FaArrowDown className="text-red-500 text-3xl" /> // Down arrow for decrease
              )}
            </div>

            {/* Stats Data */}
            <div className="flex flex-col">
              <div className="font-medium text-gray-500 text-sm leading-5">
                {item.label}
              </div>
              <div className="text-gray-900 text-2xl font-bold leading-[33.25px]">
                {item.number}
              </div>
              <div
                className={`text-sm font-medium ${
                  item.percentage >= 0 ? "text-green-500" : "text-red-500"
                }`}
              >
                {item.percentage >= 0
                  ? `${item.percentage}% Increase`
                  : `${Math.abs(item.percentage)}% Decrease`}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TotalStat;
