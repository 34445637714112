// Popup.js
import React, { useState } from "react";
import styles from "./PopUp.module.css";

const PopUp = ({ onClose }) => {
  const [note, setNote] = useState("");

  const handleSaveNote = () => {
    // Add save note logic here
    console.log("Note saved:", note);
    onClose();
  };

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <h2>Add a note</h2>
        <textarea
          className={styles.popupTextarea}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Enter your note here..."
        />
        <div className={styles.popupActions}>
          <button className={styles.popupButton} onClick={handleSaveNote}>
            Save Note
          </button>
          <button className={styles.popupButton} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
