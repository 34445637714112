import profilePic from "./profile.jpg";

export const participantData = [
  {
    id: 1,
    name: "Alice Smith",
    username: "alice123",
    profilePic,
    category: "Workshop",
    doj: "2024-07-15",
    eventsParticipated: 100,
    winning: "2024-07-16",
    ongoing: {
      eventName: "Workshop 101",
      category: "Training",
      rank: 1,
      startDate: "2024-07-15",
      endDate: "2024-07-16",
      venue: "Main Hall",
      actions: {
        edit: "editFunction1",
        delete: "deleteFunction1",
      },
    },
    participated: [
      {
        eventName: "Workshop 101",
        category: "Training",
        rank: 1,
        startDate: "2024-07-15",
        endDate: "2024-07-16",
        venue: "Main Hall",
        actions: {
          edit: "editFunction1",
          delete: "deleteFunction1",
        },
      },
      {
        eventName: "Hackathon 2023",
        category: "Competition",
        rank: 2,
        startDate: "2023-05-05",
        endDate: "2023-05-06",
        venue: "Lab 2",
        actions: {
          edit: "editFunction4",
          delete: "deleteFunction4",
        },
      },
    ],
    posts: [
      {
        id: 1,
        imageUrl: "https://via.placeholder.com/150",
        date: "2024-07-05",
        time: "10:00 AM",
        rank: 1,
      },
      {
        id: 2,
        imageUrl: "https://via.placeholder.com/150",
        date: "2024-07-05",
        time: "12:00 PM",
        rank: 2,
      },
    ],
  },
  {
    id: 2,
    name: "Bob Johnson",
    username: "bob456",
    profilePic,
    category: "Seminar",
    doj: "2023-06-10",
    eventsParticipated: 50,
    winning: "2023-06-11",
    ongoing: {
      eventName: "Seminar on AI",
      category: "Education",
      rank: 2,
      startDate: "2023-06-10",
      endDate: "2023-06-11",
      venue: "Conference Room A",
      actions: {
        edit: "editFunction2",
        delete: "deleteFunction2",
      },
    },
    participated: [
      {
        eventName: "Seminar on AI",
        category: "Education",
        rank: 2,
        startDate: "2023-06-10",
        endDate: "2023-06-11",
        venue: "Conference Room A",
        actions: {
          edit: "editFunction2",
          delete: "deleteFunction2",
        },
      },
    ],
    posts: [
      {
        id: 3,
        imageUrl: "https://via.placeholder.com/150",
        date: "2024-07-06",
        time: "2:00 PM",
        rank: 3,
      },
      {
        id: 4,
        imageUrl: "https://via.placeholder.com/150",
        date: "2024-07-06",
        time: "4:00 PM",
        rank: 4,
      },
    ],
  },
  {
    id: 3,
    name: "Charlie Davis",
    username: "charlie789",
    profilePic,
    category: "Hackathon",
    doj: "2022-05-05",
    eventsParticipated: 75,
    winning: "2022-05-06",
    ongoing: {
      eventName: "Hackathon 2022",
      category: "Competition",
      rank: 3,
      startDate: "2022-05-05",
      endDate: "2022-05-06",
      venue: "Lab 1",
      actions: {
        edit: "editFunction3",
        delete: "deleteFunction3",
      },
    },
    participated: [
      {
        eventName: "Hackathon 2022",
        category: "Competition",
        rank: 3,
        startDate: "2022-05-05",
        endDate: "2022-05-06",
        venue: "Lab 1",
        actions: {
          edit: "editFunction3",
          delete: "deleteFunction3",
        },
      },
    ],
    posts: [
      {
        id: 5,
        imageUrl: "https://via.placeholder.com/150",
        date: "2024-07-07",
        time: "10:00 AM",
        rank: 5,
      },
      {
        id: 6,
        imageUrl: "https://via.placeholder.com/150",
        date: "2024-07-07",
        time: "11:00 AM",
        rank: 6,
      },
    ],
  },
];
