import React from 'react';

export const FairPolicy = () => {
  const policyData = {
    lastUpdated: "September 6, 2024",
    sections: [
      {
        title: "Introduction",
        content: `At Namasvi, we strive to maintain a fair, inclusive, and respectful environment for all participants, organizers, sponsors, and audience members involved in our cultural events and fashion shows. This Fair Play Policy outlines the standards of conduct expected within our community.`
      },
      {
        title: "Eligibility and Participation",
        content: `Age Requirement: Participants must be 18 years or older unless otherwise specified.\nAuthenticity: All participants must provide genuine information and not create multiple accounts to manipulate voting or participation.`
      },
      {
        title: "Voting Integrity",
        content: `Fair Voting Practices: Voting through Instagram/Facebook likes must reflect genuine support. Any attempt to artificially inflate votes (e.g., using bots, purchasing likes) is prohibited.\nMonitoring: We reserve the right to monitor voting patterns and take action against fraudulent activities.\nDisqualification: Participants found engaging in vote manipulation may be disqualified from the event and any associated rewards.`
      },
      {
        title: "Content Standards",
        content: `Respectful Communication: All interactions within the App and related social media platforms should be respectful and free from harassment, hate speech, or discriminatory remarks.\nAppropriate Content: User-generated content must adhere to community guidelines, avoiding offensive, explicit, or inappropriate material.\nIntellectual Property: Respect the intellectual property rights of others. Do not upload content that infringes on copyrights, trademarks, or other proprietary rights.`
      },
      {
        title: "Collaboration with Sponsors",
        content: `Authentic Engagement: When collaborating with sponsors, participants must engage authentically, avoiding misleading promotions or endorsements.\nDisclosure: Clearly disclose any sponsored collaborations as per applicable advertising standards and regulations.\nCompliance: Adhere to the specific guidelines and requirements set forth by each sponsor.`
      },
      {
        title: "Consequences of Policy Violations",
        content: `Violations of this Fair Play Policy may result in:\nWarnings: Initial infractions may result in warnings.\nSuspension: Temporary suspension of account access.\nDisqualification: Permanent removal from events and loss of any associated rewards or recognitions.\nLegal Action: In cases of severe misconduct, legal actions may be pursued.`
      },
      {
        title: "Reporting Violations",
        content: `If you witness or experience any violations of this policy, please report them to us at support@namasvi.com with detailed information.`
      },
      {
        title: "Policy Changes",
        content: `We may update this Fair Play Policy periodically. Changes will be communicated through the App and relevant channels.`
      },
      {
        title: "Contact Us",
        content: `For questions or concerns about this Fair Play Policy, please contact us at:\nEmail: support@namasvi.com `
      }
    ]
  };

  return (
    <div className='w-[76vw] ml-[20vw] mt-28 p-6'>
      <h1 className='text-5xl font-bold text-center mb-6'>Fair Play Policy</h1>
      <p className='text-lg text-gray-600 text-center mb-8'>
        Last Updated: {policyData.lastUpdated}
      </p>
      {policyData.sections.map((section, index) => (
        <div key={index} className='mb-8'>
          <h2 className='text-3xl font-semibold text-blue-800 mb-4'>{section.title}</h2>
          <p className='text-lg text-gray-700 whitespace-pre-line'>{section.content}</p>
        </div>
      ))}
    </div>
  );
};
