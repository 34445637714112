import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ParticipantPreference.module.css";
import backIcon from "../backIcon.png";
// import { participantData } from "../participantData";

const ParticipantPreference = () => {
  const navigate = useNavigate();
  const { participantId } = useParams();

  const navigateToParticipantDetail = () => {
    navigate(`/participants/${participantId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button
          type="button"
          className={styles.backButton}
          onClick={navigateToParticipantDetail}
        >
          <img src={backIcon} alt="backIcon" />
        </button>
        <h2 className={styles.title}>Preferences</h2>
      </div>
      <form className={styles.eventForm}>
        <div className={styles.formGroup}>
          <label className={styles.label}>Selection Location:</label>
          <select className={styles.select}>
            <option value="location1">Location 1</option>
            <option value="location2">Location 2</option>
            <option value="location3">Location 3</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Question 1:</label>
          <input type="text" className={styles.input} />
        </div>

        <div className={styles.formGroupChoices}>
          <label className={styles.label}>Choices:</label>
          <div className={styles.choices}>
            <input type="text" className={styles.input} />
            <input type="text" className={styles.input} />
            <input type="text" className={styles.input} />
            <input type="text" className={styles.input} />
          </div>
        </div>

        <button
          type="button"
          className={styles.viewButton}
          onClick={() => navigate("/")}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default ParticipantPreference;
