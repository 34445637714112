import React from "react";
import SponsorProfile from "./SponsorProfile";
import ApprovedRequestTable from "./ApprovedRequestTable";
import PendingRequestTable from "./PendingRequestTable";
import RejectedRequestTable from "./RejectedRequestTable";

const SponsorDetails = () => {
  return (
    <div>
      <SponsorProfile />
      <ApprovedRequestTable />
      <RejectedRequestTable />
      <PendingRequestTable />
    </div>
  );
};

export default SponsorDetails;
