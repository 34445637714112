import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components for Chart.js v3+
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const FacebookInsightsChart = ({ accessToken, instagramBusinessAccountId }) => {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [insights, setInsights] = useState(null);
  const [insightsData, setInsightsData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null); // State to track the selected button
  const [chartType, setChartType] = useState('line'); // State to manage the chart type
  const [isWeekly, setIsWeekly] = useState(true); // State to toggle between weekly and monthly
  const [chartLabel, setChartLabel] = useState("Facebook Insights"); // State to manage the chart label

  // Options for the chart (move this above usage)
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const getResponse = async (url) => {
    try {
      const response = await axios.get(url, {
        params: {
          access_token: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching media details:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        if (accessToken && instagramBusinessAccountId) {
          const initialUrl = `${apiDomain}/api/v2/user/monthly?id=${instagramBusinessAccountId}&access_token=${accessToken}&period=day`;
          const data = await getResponse(initialUrl);
          console.log("filtereddata", data);
          console.log("insights", data);
          setInsights(data.data);
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch insights
    fetchInsights();
  }, [accessToken, instagramBusinessAccountId]);

  console.log("insights data user", insights);

  // Sample data for the last 7 days (weekly)
  const weeklyData = insightsData ? insightsData.values.map(item => item.value).slice(-7) : [0, 0, 0, 0, 0, 0, 0];
  // Sample data for the last 30 days (monthly)
  const monthlyData = insightsData ? insightsData.values.map(item => item.value) : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

  // Generate labels for the charts
  const generateWeeklyLabels = () => {
    return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  };

  const generateMonthlyLabels = () => {
    return Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`);
  };

  // Data for the chart
  const data = {
    labels: isWeekly ? generateWeeklyLabels() : generateMonthlyLabels(),
    datasets: [
      {
        label: chartLabel, // Use the chartLabel state here
        data: isWeekly ? weeklyData : monthlyData,
        fill: false,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  // Different Chart Options
  const smoothLineOptions = {
    ...options,
    elements: {
      line: {
        tension: 0.4, // Smooth the lines
      },
    },
  };

  const areaChartOptions = {
    ...options,
    elements: {
      line: {
        fill: true, // Enable fill for the area chart
      },
    },
  };

  const dottedLineOptions = {
    ...options,
    elements: {
      line: {
        borderDash: [5, 5], // Dotted line style
      },
    },
  };

  // Handle button click to highlight the selected button
  const handleClick = (index, insight) => {
    setSelectedButton(index);
    setInsightsData(insight);
    setChartLabel(insight.name); // Update the chart label with the button text
  };

  return (
    <div className="w-full mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Facebook Insights</h2>
        <div>
          <button
            className={`px-4 py-2 rounded ${isWeekly ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
            onClick={() => setIsWeekly(true)}
          >
            Weekly
          </button>
          <button
            className={`ml-2 px-4 py-2 rounded ${!isWeekly ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
            onClick={() => setIsWeekly(false)}
          >
            Monthly
          </button>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex flex-wrap gap-2 mb-2">
          {insights &&
            insights.map((x, index) => (
              <button
                key={index}
                id={`click-btn${index}`}
                className={`border p-2 rounded-md transition duration-300 ease-in-out ${
                  selectedButton === index ? 'bg-blue-600 text-white' : 'bg-blue-100 text-blue-600'
                } hover:bg-blue-500 hover:text-white`}
                onClick={() => handleClick(index, x)}
              >
                {x.name}
              </button>
            ))}
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-center items-center space-x-2 border-t pt-4">
          <button
            className={`px-4 py-2 rounded ${chartType === 'line' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
            onClick={() => setChartType('line')}
          >
            Smooth Line
          </button>
          <button
            className={`px-4 py-2 rounded ${chartType === 'bar' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
            onClick={() => setChartType('bar')}
          >
            Bar Chart
          </button>
          <button
            className={`px-4 py-2 rounded ${chartType === 'area' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
            onClick={() => setChartType('area')}
          >
            Area Chart
          </button>
          <button
            className={`px-4 py-2 rounded ${chartType === 'dotted' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
            onClick={() => setChartType('dotted')}
          >
            Dotted Line
          </button>
        </div>
      </div>
      <div>
        {chartType === 'line' && <Line data={data} options={smoothLineOptions} />}
        {chartType === 'bar' && <Bar data={data} options={options} />}
        {chartType === 'area' && <Line data={data} options={areaChartOptions} />}
        {chartType === 'dotted' && <Line data={data} options={dottedLineOptions} />}
      </div>
    </div>
  );
};

export default FacebookInsightsChart;
