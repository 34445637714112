import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const data = [
  { icon: "up", label: "Total Participant", number: "85", percentage: 60 },
  { icon: "down", label: "This month", number: "85", percentage: -40 },
  { icon: "up", label: "Active", number: "120", percentage: 90 },
];

const TotalStatParticipant = () => {
  return (
    <div className="flex flex-wrap justify-between mt-32 gap-6">
      {data.map((item, index) => (
        <div
          key={index}
          className="flex items-center w-full sm:w-1/2 lg:w-[30%] bg-white rounded-lg shadow-md p-4 transition transform hover:scale-105 hover:shadow-lg"
        >
          <div className="relative flex justify-center items-center w-16 h-16 border-4 border-black rounded-full overflow-hidden mr-4">
            <FontAwesomeIcon
              icon={item.icon === "up" ? faArrowUp : faArrowDown}
              className={`text-xl ${
                item.icon === "up" ? "text-green-500" : "text-red-500"
              }`}
            />
          </div>
          <div className="flex flex-col">
            <div className="text-gray-500 font-medium text-sm">{item.label}</div>
            <div className="text-gray-900 text-xl font-semibold">
              {item.number}
            </div>
            {/* Percentage (Increase or Decrease) */}
            <div
              className={`text-sm font-semibold ${
                item.percentage > 0 ? "text-green-500" : "text-red-500"
              }`}
            >
              {item.percentage > 0
                ? `+${item.percentage}% Increase`
                : `${item.percentage}% Decrease`}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TotalStatParticipant;
