import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SponsorDetails.module.css";
import { participantData } from "../../Participants/participantData"; // Adjust the path as needed
import Popup from "../collaborationRequest/PopUp";

const PendingRequestTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All");
  const eventsPerPage = 4;

  const navigate = useNavigate();

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = participantData
    .filter((event) => (filter === "All" ? true : event.category === filter))
    .slice(indexOfFirstEvent, indexOfLastEvent);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [showPopup, setShowPopup] = useState(false);

  const handleDenyRequest = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  //   const handleFilterChange = (event) => {
  //     setFilter(event.target.value);
  //     setCurrentPage(1);
  //   };

  return (
    <>
      {/* <div className={styles.filterCreateContainer}>
        <div>
          <select
            className={styles.filterButton}
            value={filter}
            onChange={handleFilterChange}
          >
            <option value="ongoing">Ongoing Events</option>
            <option value="week">Past week Events</option>
            <option value="month">Past month Events</option>
            <option value="year">Past year Events</option>
          </select>
          <button
            className={styles.createButton}
            onClick={() => navigate("/edit-participant-question")}
          >
            Edit Questionnaire
          </button>
        </div>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search..."
            className={styles.searchInput}
          />
        </div>
      </div> */}
      <div>
        <div className={styles.eventsTable}>
          <h2 className={styles.tableHeader}>Pending Requesets</h2>

          <table className={styles.tableEvent}>
            <thead>
              <tr className={styles.eventTr}>
                <th>Participant Name</th>
                <th>Category</th>
                <th>Date Of Joining</th>
                <th>Ongoing Event</th>
                <th>Winnings</th>
                <th>Events Participated</th>
              </tr>
            </thead>
            <tbody>
              {currentEvents.map((event) => (
                <tr
                  key={event.id}
                  onClick={() => navigate(`/participants/${event.id}`)}
                >
                  <td>{event.name}</td>
                  <td>{event.category}</td>
                  <td>{event.doj}</td>
                  {/* Accessing specific properties of event.ongoing */}
                  <td>{event.ongoing.eventName}</td>
                  <td>{event.winning}</td>
                  <td>{event.eventsParticipated}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.pagination}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.paginationButton}
          >
            Prev
          </button>
          <p className={styles.pageText}>
            Page {currentPage}/
            {Math.ceil(participantData.length / eventsPerPage)}
          </p>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastEvent >= participantData.length}
            className={styles.paginationButton}
          >
            Next
          </button>
        </div>
        <div className={styles.btnGroup}>
          <button className={styles.acceptButton} onClick={() => navigate("/")}>
            Accepte Request
          </button>
          <div>
            <button className={styles.denyButton} onClick={handleDenyRequest}>
              Deny Request
            </button>

            {showPopup && <Popup onClose={handleClosePopup} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingRequestTable;
