import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import styles from "./RoleTable.module.css";
import { roleData } from "./roleData";
// const eventsData = [
//   {
//     id: 1,
//     name: "Event 1",
//     category: "Music",
//     participants: 150,
//     startDate: "2024-07-01",
//     endDate: "2024-07-03",
//     venue: "Concert Hall",
//   },
//   {
//     id: 2,
//     name: "Event 2",
//     category: "Sports",
//     participants: 200,
//     startDate: "2024-07-05",
//     endDate: "2024-07-07",
//     venue: "Stadium",
//   },
//   {
//     id: 3,
//     name: "Event 3",
//     category: "Conference",
//     participants: 300,
//     startDate: "2024-07-10",
//     endDate: "2024-07-12",
//     venue: "Exhibition Center",
//   },
//   {
//     id: 42,
//     name: "Event 4",
//     category: "Workshop",
//     participants: 100,
//     startDate: "2024-07-15",
//     endDate: "2024-07-16",
//     venue: "Training Room",
//   },
//   {
//     id: 40,
//     name: "Event 4",
//     category: "Workshop",
//     participants: 100,
//     startDate: "2024-07-15",
//     endDate: "2024-07-16",
//     venue: "Training Room",
//   },
//   {
//     id: 33,
//     name: "Event 4",
//     category: "Workshop",
//     participants: 100,
//     startDate: "2024-07-15",
//     endDate: "2024-07-16",
//     venue: "Training Room",
//   },
//   {
//     id: 43,
//     name: "Event 4",
//     category: "Workshop",
//     participants: 100,
//     startDate: "2024-07-15",
//     endDate: "2024-07-16",
//     venue: "Training Room",
//   },
//   // Add more dummy data as needed
// ];

// roles table
const RoleTable = () => {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All"); // Filter state
  const eventsPerPage = 4; // Number of events to display per page

  const navigate = useNavigate(); // Initialize navigate

  // Pagination logic
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = roleData
    .filter((event) => (filter === "All" ? true : event.category === filter))
    .slice(indexOfFirstEvent, indexOfLastEvent);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle filter change
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  return (
    <>
      <div className={styles.filterCreateContainer}>
        <div>
          {/* <label htmlFor="venue">Select Venue</label> */}

          <select
            className={styles.filterButton}
            value={filter}
            onChange={handleFilterChange}
          >
            {/* Filters */}
            {/* <div className={styles.optionContainer}> */}
            <option value="ongoin">Ongoing Events</option>
            <option value="week">Past week Events</option>
            <option value="month">Past month Events</option>
            <option value="year">Past year Events</option>
            {/* </div> */}
          </select>
          <button
            className={styles.createButton}
            onClick={() => navigate("/add-role-form")} // Navigate to EventForm
          >
            + Add New Role
          </button>
        </div>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search..."
            className={styles.searchInput}
          />
        </div>
      </div>
      <div>
        {/* Events table */}
        <div className={styles.eventsTable}>
          <table className={styles.tableEvent}>
            <thead>
              <tr className={styles.eventTr}>
                <th>Role Name</th>
                <th>Location</th>
                <th>Event Name</th>
                <th>Assigned Users</th>
                <th>Donations Received</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentEvents.map((role) => (
                <tr key={role.id}>
                  <td>{role.roleName}</td>
                  <td>{role.location}</td>
                  <td>{role.eventName}</td>
                  <td>{role.assignedUsers}</td>
                  <td>{role.donationsReceived}</td>
                  <td>
                    <button
                      className={styles.editButton}
                      onClick={() => navigate("/edit-role")}
                    >
                      Edit
                    </button>
                    <button className={styles.deleteButton}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className={styles.pagination}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.paginationButton}
          >
            Prev
          </button>
          <p className={styles.pageText}>
            Page {currentPage}/{Math.ceil(roleData.length / eventsPerPage)}
          </p>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastEvent >= roleData.length}
            className={styles.paginationButton}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default RoleTable;
