import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import hamburgerIcon from "./hamburger.png";

import dashboard from './assets/icon1.svg';
import participant from './assets/icon2.svg';
import sponsor from './assets/icon3.svg';
import event from './assets/icon4.svg';
import dashboard5 from './assets/icon5.svg';
import dashboard6 from './assets/icon6.svg';
import dashboard7 from './assets/icon7.svg';
import dashboard8 from './assets/icon8.svg';
import dashboard9 from './assets/icon9.svg';

const SideNav = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selected, setSelected] = useState("/"); // Default selected item
  const { access_token } = useParams();
  const location = useLocation();
  
  // console.log("access_token", access_token);

  // const toggleCollapse = () => {
  //   setIsCollapsed(!isCollapsed);
  // };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleNavClick = (path) => {
    setSelected(path);
  };

  return (
    <div
      className={`fixed top-0 left-0 h-screen bg-[#fff] text-white transition-all duration-300 ease-in-out overflow-y-auto z-10 border-r ${
        isCollapsed ? "w-[50px]" : "w-[18vw]"
      }`}
    >
      <div
        className={`flex items-center justify-center my-6 text-center text-2xl font-bold ${
          isCollapsed ? "hidden" : ""
        }`}
      >
        <label className="text-xl text-[#202224]"><span className="text-[#4880FF]">NAMASVI</span>Nexus</label>
      </div>
      <div className="flex flex-col space-y-4 px-[1vw]">
        <NavItem
          icon={dashboard}
          text="Dashboard"
          link="/"
          isCollapsed={isCollapsed}
          isSelected={selected === "/"}
          onClick={() => handleNavClick("/")}
        />
        <NavItem
          icon={participant}
          text="Participants"
          link="/participants"
          isCollapsed={isCollapsed}
          isSelected={selected === "/participants"}
          onClick={() => handleNavClick("/participants")}
        />
        <NavItem
          icon={event}
          text="Category"
          link="/category"
          isCollapsed={isCollapsed}
          isSelected={selected === "/category"}
          onClick={() => handleNavClick("/category")}
        />
        <NavItem
          icon={dashboard5}
          text="Venue"
          link="/venue"
          isCollapsed={isCollapsed}
          isSelected={selected === "/venue"}
          onClick={() => handleNavClick("/venue")}
        />
        <NavItem
          icon={sponsor}
          text="Sponsors"
          link="/sponsors"
          isCollapsed={isCollapsed}
          isSelected={selected === "/sponsors"}
          onClick={() => handleNavClick("/sponsors")}
        />
        <NavItem
          icon={dashboard6}
          text="Events"
          link="/events"
          isCollapsed={isCollapsed}
          isSelected={selected === "/events"}
          onClick={() => handleNavClick("/events")}
        />
        <NavItem
          icon={dashboard7}
          text="Payment"
          link="/donations"
          isCollapsed={isCollapsed}
          isSelected={selected === "/donations"}
          onClick={() => handleNavClick("/donations")}
        />
  
        <NavItem
          icon={dashboard8}
          text="#HashTag"
          link="/eventhashtag"
          isCollapsed={isCollapsed}
          isSelected={selected === "/eventhashtag"}
          onClick={() => handleNavClick("/eventhashtag")}
        />
         <NavItem
          icon={dashboard8}
          text="Privacy Policy"
          link="/privacypolicy"
          isCollapsed={isCollapsed}
          isSelected={selected === "/privacypolicy"}
          onClick={() => handleNavClick("/privacypolicy")}
        />
         <NavItem
          icon={dashboard8}
          text="Fair Play Policy"
          link="/fairpolicy"
          isCollapsed={isCollapsed}
          isSelected={selected === "/fairpolicy"}
          onClick={() => handleNavClick("/fairpolicy")}
        />
           <NavItem
          icon={dashboard8}
          text="Terms & Conditions"
          link="/termcondition"
          isCollapsed={isCollapsed}
          isSelected={selected === "/termcondition"}
          onClick={() => handleNavClick("/termcondition")}
        />
        <NavItem
          icon={event}
          text="Voting Settings"
          link="/voting-settings"
          isCollapsed={isCollapsed}
          isSelected={selected === "/voting-settings"}
          onClick={() => handleNavClick("/voting-settings")}
        />
        <div className="relative">
          <div onClick={toggleSettings}>
            <NavItem
              icon={dashboard9}
              text="Settings"
              link="#"
              isCollapsed={isCollapsed}
              isSelected={settingsOpen}
              onClick={() => handleNavClick("#")}
            />
          </div>
          {settingsOpen && (
            <div className="flex flex-col bg-gray-700 pl-6">
              <Link
                to="/role-management"
                className="text-gray-300 py-2 hover:bg-gray-600"
              >
                Role Management
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NavItem = ({ icon, text, link, isCollapsed, isSelected, onClick }) => {
  return (
    <Link
      to={link}
      className={`flex items-center py-3 px-6 rounded-md font-semibold transition-colors duration-300 
        ${isSelected ? 'bg-[#4880FF] text-white' : 'text-[#202224]'} 
        hover:bg-[#4880FF] hover:text-white`}
      onClick={onClick}
    >
      <img src={icon} alt="Icon" className="w-6 h-6 mr-4" />
      {!isCollapsed && <span className="text-sm">{text}</span>}
    </Link>
  );
};


export default SideNav;

