// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage} from "firebase/storage"


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBRQaejHyPcFG0MElvS757Sh6PO4Nj70WI",
  authDomain: "namasvi-7f968.firebaseapp.com",
  projectId: "namasvi-7f968",
  storageBucket: "namasvi-7f968.appspot.com",
  messagingSenderId: "257852189744",
  appId: "1:257852189744:web:e4bfd301d7190badcb51f5",
  measurementId: "G-MN4X8QKMN9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export {storage, analytics}