import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { collabData } from "./CollaborationData";
import backIcon from "./backIcon.png";

const CollaborationRequest = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All");
  const eventsPerPage = 4;

  const navigate = useNavigate();

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = collabData
    .filter((event) => (filter === "All" ? true : event.category === filter))
    .slice(indexOfFirstEvent, indexOfLastEvent);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="px-4 mt-8">
      <div className="flex items-center mb-10">
        <button
          type="button"
          className="mr-4 p-2 bg-white border border-gray-300 rounded cursor-pointer"
          onClick={() => navigate("/sponsors")}
        >
          <img src={backIcon} alt="Back" />
        </button>
        <h2 className="text-black font-semibold text-3xl leading-tight m-0">Collaboration Requests</h2>
      </div>

      <h2 className="text-black font-semibold text-2xl leading-snug">Pending Requests</h2>

      <div className="w-full overflow-x-auto mt-4 bg-white border border-gray-300 rounded-lg">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 h-16">
              <th className="text-center text-gray-700 font-semibold text-base py-4">Sponsor Name</th>
              <th className="text-center text-gray-700 font-semibold text-base py-4">Participant Name</th>
              <th className="text-center text-gray-700 font-semibold text-base py-4">Category</th>
              <th className="text-center text-gray-700 font-semibold text-base py-4">Date Of Joining</th>
              <th className="text-center text-gray-700 font-semibold text-base py-4">Ongoing Event</th>
              <th className="text-center text-gray-700 font-semibold text-base py-4">Winnings</th>
              <th className="text-center text-gray-700 font-semibold text-base py-4">Events Participated</th>
            </tr>
          </thead>
          <tbody>
            {currentEvents.map((sponsor) => (
              <tr key={sponsor.id} className="bg-white border-b border-gray-200 h-20">
                <td className="text-center text-gray-800 text-sm font-normal py-4">{sponsor.sponsorName}</td>
                <td className="text-center text-gray-800 text-sm font-normal py-4">{sponsor.participantName}</td>
                <td className="text-center text-gray-800 text-sm font-normal py-4">{sponsor.category}</td>
                <td className="text-center text-gray-800 text-sm font-normal py-4">{sponsor.doj}</td>
                <td className="text-center text-gray-800 text-sm font-normal py-4">{sponsor.ongoingEvent}</td>
                <td className="text-center text-gray-800 text-sm font-normal py-4">{sponsor.winnings}</td>
                <td className="text-center text-gray-800 text-sm font-normal py-4">{sponsor.eventsParticipated}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-4 py-2 border rounded ${
            currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-white border-gray-300"
          }`}
        >
          Prev
        </button>
        <p className="text-gray-700 font-medium text-base">
          Page {currentPage}/{Math.ceil(collabData.length / eventsPerPage)}
        </p>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastEvent >= collabData.length}
          className={`px-4 py-2 border rounded ${
            indexOfLastEvent >= collabData.length
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-white border-gray-300"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CollaborationRequest;
