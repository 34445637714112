import React, { useState } from "react";
import { storage } from "./firebaseConfig"; // Import the initialized storage instance
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Import Firebase Storage functions

// Define the async function to upload a file
export const uploadFile = async (file) => {
    if (!file) throw new Error("No file provided");
  
    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, `uploads/${file.name}`);
  
    // Return a promise that resolves with the file's download URL
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      // Monitor the upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // You can add progress handling here if needed
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          // Handle errors during upload
          console.error("Upload error:", error);
          reject(error);
        },
        async () => {
          // Get the download URL once the upload is complete
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL); // Resolve with the download URL
          } catch (error) {
            reject(error); // Reject if there's an error getting the URL
          }
        }
      );
    });
  };