import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ParticipantDetails.module.css";
import { participantData } from "../participantData"; // Adjust the path as needed
import editIcon from "../../../assets/edit.png";
import delIcon from "../../../assets/del.png";
const ParticipantParticipatedTable = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 2;

  const participatedEvents = participantData.flatMap((participant) =>
    participant.participated.map((event) => ({
      id: participant.id,
      name: participant.name,
      ...event,
    }))
  );

  // Logic for pagination
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = participatedEvents.slice(
    indexOfFirstEvent,
    indexOfLastEvent
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className={styles.eventsTable}>
      <h2 className={styles.tableHeader}>Participated</h2>
      <div className={styles.tableWrapper}>
        <table className={styles.tableEvent}>
          <thead>
            <tr className={styles.eventTr}>
              <th>Event Name</th>
              <th>Category</th>
              <th>Rank</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Venue</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {currentEvents.map((event) => (
              <tr key={`${event.id}-${event.eventName}`}>
                <td>{event.eventName}</td>
                <td>{event.category}</td>
                <td>{event.rank}</td>
                <td>{event.startDate}</td>
                <td>{event.endDate}</td>
                <td>{event.venue}</td>
                <td>
                  <button className={styles.editButton}>
                    <img src={editIcon} slt="edit" />
                  </button>
                  <button className={styles.deleteButton}>
                    <img src={delIcon} alt="delete" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.pagination}>
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={styles.paginationButton}
        >
          Prev
        </button>
        <p className={styles.pageText}>
          Page {currentPage}/
          {Math.ceil(participatedEvents.length / eventsPerPage)}
        </p>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastEvent >= participatedEvents.length}
          className={styles.paginationButton}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ParticipantParticipatedTable;
