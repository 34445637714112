import React, { useState } from "react";
import { Line, Bar, Pie, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

// Register the required components for Chart.js
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const InsightsGraph = () => {
  const [isWeekly, setIsWeekly] = useState(true); // Toggle between weekly and monthly view
  const [selectedChart, setSelectedChart] = useState('line'); // Track the selected chart type

  // Static data for weekly insights
  const weeklyData = [100, 200, 150, 300, 250, 400, 350]; // Replace with actual data as needed

  // Static data for one month insights (only 30 days)
  const monthlyData = [
    10, 12, 15, 14, 16, 20, 18, 24, 30, 40, 32, 28, 25, 22, 20, 30, 40, 45, 38, 35, 32, 28, 25, 22, 20, 18, 16, 12, 10, 5
  ]; // Replace with actual data as needed

  // Generate labels for weekly and monthly views
  const weeklyLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const monthlyLabels = Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`);

  // Chart data configuration
  const chartData = {
    labels: isWeekly ? weeklyLabels : monthlyLabels,
    datasets: [
      {
        label: "Insights Data",
        data: isWeekly ? weeklyData : monthlyData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(100, 181, 246, 0.6)",
        ],
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  // Common chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        backgroundColor: '#ffcc99',
        titleFont: { size: 16 },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // Chart components to render based on selected chart type
  const renderChart = () => {
    switch (selectedChart) {
      case 'line':
        return <Line data={chartData} options={chartOptions} />;
      case 'bar':
        return <Bar data={chartData} options={chartOptions} />;
      case 'pie':
        return <Pie data={chartData} options={chartOptions} />;
      case 'doughnut':
        return <Doughnut data={chartData} options={chartOptions} />;
      default:
        return <Line data={chartData} options={chartOptions} />;
    }
  };

  return (
    <div className="w-full p-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold">Insights Graph</h2>
        <button
          onClick={() => setIsWeekly(!isWeekly)}
          className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700"
        >
          {isWeekly ? "Switch to Monthly" : "Switch to Weekly"}
        </button>
      </div>

      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setSelectedChart('line')}
          className={`px-4 py-2 rounded ${selectedChart === 'line' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700`}
        >
          Line Chart
        </button>
        <button
          onClick={() => setSelectedChart('bar')}
          className={`px-4 py-2 rounded ${selectedChart === 'bar' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700`}
        >
          Bar Chart
        </button>
        <button
          onClick={() => setSelectedChart('pie')}
          className={`px-4 py-2 rounded ${selectedChart === 'pie' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700`}
        >
          Pie Chart
        </button>
        <button
          onClick={() => setSelectedChart('doughnut')}
          className={`px-4 py-2 rounded ${selectedChart === 'doughnut' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700`}
        >
          Doughnut Chart
        </button>
      </div>

      <div className="w-full h-96">
        {renderChart()}
      </div>
    </div>
  );
};

export default InsightsGraph;
