import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./EditEventForm.module.css";
import backIcon from ".././backIcon.png";
import editIcon from "../../../assets/edit.png"; // Ensure correct path to the icon

const EditEventForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const event = location.state?.event;

  const [formData, setFormData] = useState({
    eventName: "",
    category: "",
    description: "",
    startDate: "",
    endDate: "",
    venue: "",
  });

  useEffect(() => {
    if (event) {
      setFormData({
        eventName: event.name,
        category: event.category,
        description: event.description || "",
        startDate: event.startDate,
        endDate: event.endDate,
        venue: event.venue,
      });
    }
  }, [event]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data saved:", formData);
    navigate("/edit-event-form");
  };

  return (
    <div className={styles.container}>
      <form className={styles.eventForm} onSubmit={handleSubmit}>
        <div className={styles.header}>
          <button
            type="button"
            className={styles.backButton}
            onClick={() => navigate("/")}
          >
            <img src={backIcon} alt="Back Icon" />
          </button>
          <h2 className={styles.title}>{event ? event.name : "Edit Event"}</h2>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="eventName">Name of the Event</label>
            <input
              type="text"
              id="eventName"
              name="eventName"
              value={formData.eventName}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className={styles.column}>
            <label htmlFor="category">Select Category</label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className={styles.fullWidth}
              disabled
            >
              <option value="">Select Category</option>
              <option value="Music">Music</option>
              <option value="Sports">Sports</option>
              <option value="Conference">Conference</option>
              <option value="Workshop">Workshop</option>
            </select>
          </div>
        </div>
        <div className={styles.row}>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className={styles.fullWidth}
            disabled
          ></textarea>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className={styles.column}>
            <label htmlFor="endDate">End Date</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              disabled
            />
          </div>
        </div>
        <div className={styles.row}>
          <label htmlFor="venue">Venue</label>
          <div className={styles.venueInputContainer}>
            <select
              id="venue"
              name="venue"
              value={formData.venue}
              onChange={handleChange}
              className={styles.venueInput}
            >
              <option value="">Select Venue</option>
              <option value="Concert Hall">Concert Hall</option>
              <option value="Stadium">Stadium</option>
              <option value="Exhibition Center">Exhibition Center</option>
              <option value="Training Room">Training Room</option>
            </select>
            <img src={editIcon} alt="Edit Venue" className={styles.editIcon} />
          </div>
        </div>
        <div className={styles.actions}>
          <button type="submit" className={styles.saveButton}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditEventForm;
