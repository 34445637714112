export const collabData = [
  {
    sponsorName: "TechCorp Inc.",
    participantName: "Alice Johnson",
    category: "Technology",
    doj: "2021-03-15",
    ongoingEvent: "Tech Innovation Summit 2024",
    winnings: 3,
    eventsParticipated: 5,
  },
  {
    sponsorName: "HealthPlus Ltd.",
    participantName: "Bob Smith",
    category: "Healthcare",
    doj: "2022-07-22",
    ongoingEvent: "Global Health Conference 2024",
    winnings: 2,
    eventsParticipated: 4,
  },
  {
    sponsorName: "EduWorld",
    participantName: "Carol Davis",
    category: "Education",
    doj: "2023-01-10",
    ongoingEvent: "EdTech Forum 2024",
    winnings: 5,
    eventsParticipated: 7,
  },
  {
    sponsorName: "FinancePros",
    participantName: "David Brown",
    category: "Finance",
    doj: "2020-09-18",
    ongoingEvent: "Finance Leaders Summit 2024",
    winnings: 1,
    eventsParticipated: 3,
  },
  {
    sponsorName: "EcoSolutions",
    participantName: "Eve Wilson",
    category: "Environment",
    doj: "2019-05-30",
    ongoingEvent: "Sustainability Conference 2024",
    winnings: 4,
    eventsParticipated: 6,
  },
  {
    sponsorName: "AutoTech",
    participantName: "Frank Thompson",
    category: "Automotive",
    doj: "2021-11-12",
    ongoingEvent: "AutoTech Expo 2024",
    winnings: 2,
    eventsParticipated: 4,
  },
  {
    sponsorName: "MediLife",
    participantName: "Grace Lee",
    category: "Healthcare",
    doj: "2022-03-25",
    ongoingEvent: "Medical Innovations 2024",
    winnings: 3,
    eventsParticipated: 5,
  },
  {
    sponsorName: "GreenTech",
    participantName: "Hank Martinez",
    category: "Environment",
    doj: "2020-07-14",
    ongoingEvent: "GreenTech Symposium 2024",
    winnings: 5,
    eventsParticipated: 6,
  },
  {
    sponsorName: "SportsX",
    participantName: "Isabella Anderson",
    category: "Sports",
    doj: "2023-02-28",
    ongoingEvent: "Global Sports Meet 2024",
    winnings: 1,
    eventsParticipated: 3,
  },
  {
    sponsorName: "TechInnovate",
    participantName: "Jack Roberts",
    category: "Technology",
    doj: "2018-12-20",
    ongoingEvent: "Innovators Summit 2024",
    winnings: 4,
    eventsParticipated: 8,
  },
];
