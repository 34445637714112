import React from "react";
import TotalStatSponsor from "./totalSponsorStat/TotalStatSponsor";
import SponsorListTable from "./sponsorListTable/SponsorListTable";
import SponsorDetail from "./sponsorListTable/SponsorDetail";

const Sponsors = () => {
  return (
    <div>
      <TotalStatSponsor />
      <SponsorDetail />
      {/* <SponsorListTable /> */}
    </div>
  );
};

export default Sponsors;
