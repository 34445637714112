import React from "react";
import TotalStatParticipant from "./totalStatParticipant/TotalStatParticipant";
import ParticipantTable from "./participantTable/ParticipantTable";

const Participants = () => {
  return (
    <div className="w-[75vw] ml-[21vw]">
      <TotalStatParticipant />
      <ParticipantTable />
    </div>
  );
};

export default Participants;
