import React from 'react';

export const PrivacyPolicy = () => {
  const policyData = {
    lastUpdated: "September 6, 2024",
    sections: [
      {
        title: "Introduction",
        content: `Welcome to Namasvi ("we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile application (the "App") and related services.`
      },
      {
        title: "Information We Collect",
        content: `
          a. Personal Information
          \nRegistration Data: Name, email address, phone number, and other contact details when you create an account.
          \nSocial Media Profiles: Access to your Instagram and Facebook profiles, including public information, followers, and engagement metrics through Meta Profile Analytics.
          \nb. Usage Data
          \nApp Interactions: Information about how you interact with the App, including features used, event registrations, and participation in voting.
          \nDevice Information: Device type, operating system, IP address, and other technical details.
          \nc. Content
          \nUser-Generated Content: Photos, videos, posts, and other content you upload or share through the App, especially when collaborating with sponsors on your Instagram profile.`
      },
      {
        title: "How We Use Your Information",
        content: `
          Event Management: Organize and manage cultural and fashion events, including registrations, schedules, and participant communications.
          \nVoting System: Facilitate voting through likes on Instagram/Facebook to declare event winners.
          \nAnalytics: Analyze your social media profiles to provide insights and metrics.
          \nCollaborations: Enable collaborations with sponsors by allowing you to post on your Instagram profile and follow sponsors.
          \nCommunication: Send you updates, notifications, and promotional materials related to Namasvi events and features.
          \nImprovement: Enhance and personalize the App experience based on your usage patterns and feedback.`
      },
      {
        title: "Sharing Your Information",
        content: `We may share your information with:\nService Providers: Third-party vendors who assist us in operating the App, conducting our business, or servicing you.\nSponsors: Collaborating sponsors may access certain information necessary to facilitate partnerships and promotions.\nLegal Requirements: When required by law, regulation, or legal process.\nNote: We do not sell your personal information to third parties.`
      },
      {
        title: "Permissions Required from Meta",
        content: `
          To enable features like Meta Profile Analytics and in-app voting via Instagram/Facebook likes, we require permissions to access:
          \nPublic Profile Information: Including name, profile picture, and other publicly available data.
          \nUser Engagement Data: Such as likes, follows, and other interactions on your social media profiles.
          \nPost Permissions: Ability to post on your behalf in collaboration with sponsors.
          \nWe ensure that all permissions are requested in compliance with Meta’s Platform Policies and only access the data necessary for the App's functionality.`
      },
      {
        title: "Your Rights and Choices",
        content: `
          Access and Update: You can access and update your personal information through your account settings.
          \nData Deletion: Request the deletion of your personal data by contacting us at support@namasvi.com.
          \nOpt-Out: You can opt out of receiving promotional communications by following the unsubscribe instructions in those messages.`
      },
      {
        title: "Data Security",
        content: "We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction."
      },
      {
        title: "Children's Privacy",
        content: "Our App is intended for users aged 18 and above. We do not knowingly collect personal information from minors."
      },
      {
        title: "Changes to This Privacy Policy",
        content: "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date."
      },
      {
        title: "Contact Us",
        content: `If you have any questions or concerns about this Privacy Policy, please contact us at:
          \nEmail: privacy@namasvi.com`
      }
    ]
  };

  return (
    <div className='w-[76vw] ml-[20vw] mt-28 p-6'>
      <h1 className='text-5xl font-bold text-center mb-6'>Privacy Policy</h1>
      <p className='text-lg text-gray-600 text-center mb-8'>
        Last Updated: {policyData.lastUpdated}
      </p>
      {policyData.sections.map((section, index) => (
        <div key={index} className='mb-8'>
          <h2 className='text-3xl font-semibold text-blue-800 mb-4'>{section.title}</h2>
          <p className='text-lg text-gray-700 whitespace-pre-line'>{section.content}</p>
        </div>
      ))}
    </div>
  );
};
