import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from '../../../context/DataContext';
import axios from "axios";
import Cookies from 'js-cookie';

const VotingSetting = () => {
  const navigate = useNavigate();
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const accessToken = Cookies.get("fbauthToken");
  
  const { audienceVotingWeights, sponsorVotingWeights, loading } = useContext(DataContext);

  const [formData, setFormData] = useState({
    audience: {
      likes: 0,
      comments: 0,
      shares: 0,
    },
    sponsor: {
      likes: 0,
      comments: 0,
      shares: 0,
    },
  });

  useEffect(() => {
    if (!loading) {
      setFormData({
        audience: {
          likes: audienceVotingWeights?.audienceVotingWeight?.likes || "",
          comments: audienceVotingWeights?.audienceVotingWeight?.comments || "",
          shares: audienceVotingWeights?.audienceVotingWeight?.shares || "",
        },
        sponsor: {
          likes: sponsorVotingWeights?.sponsorVotingWeight?.likes || "",
          comments: sponsorVotingWeights?.sponsorVotingWeight?.comments || "",
          shares: sponsorVotingWeights?.sponsorVotingWeight?.shares || "",
        },
      });
    }
  }, [loading, audienceVotingWeights, sponsorVotingWeights]);

  const handleInputChange = (section, e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [name]: value,
      },
    }));
  };

  const handleSaveClick = async (type) => {
    try {
      const response = await axios.put(`${apiDomain}/api/v2/updatevotingweight?type=${type}`, formData[type], {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          access_token: accessToken,
        },
      });
  
      if (response.data.response.status_code === "200") {
        alert('Update successful');
      } else {
        alert(`Update failed: ${response.data.response.message}`);
      }
    } catch (error) {
      alert('Error updating voting data:', error);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="flex flex-col items-start p-6 bg-gray-100 rounded-lg mt-4">
      <form className="w-full max-w-3xl bg-white px-8 pb-8 shadow-lg rounded-lg space-y-8">
      
        {/* Sponsor Voting Weight */}
        <div className="mt-8">
          <h2 className="text-3xl font-semibold text-gray-800">Sponsor Voting Weight</h2>
        </div>

        <div className="space-y-6">
          {/* Likes */}
          <div className="flex flex-col">
            <label htmlFor="likesponsor" className="text-lg font-semibold text-gray-600">1 Like:</label>
            <input
              type="number"
              id="likesponsor"
              name="likes"
              className="mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              value={formData.sponsor.likes}
              onChange={(e) => handleInputChange("sponsor", e)}
            />
          </div>

          {/* Comments */}
          <div className="flex flex-col">
            <label htmlFor="commentsponsor" className="text-lg font-semibold text-gray-600">1 Comment:</label>
            <input
              type="number"
              id="commentsponsor"
              name="comments"
              className="mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              value={formData.sponsor.comments}
              onChange={(e) => handleInputChange("sponsor", e)}
            />
          </div>

          {/* Shares */}
          <div className="flex flex-col">
            <label htmlFor="sharesponsor" className="text-lg font-semibold text-gray-600">1 View:</label>
            <input
              type="number"
              id="sharesponsor"
              name="shares"
              className="mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              value={formData.sponsor.shares}
              onChange={(e) => handleInputChange("sponsor", e)}
            />
          </div>

          <button
            type="button"
            className="w-full mt-4 py-2 px-4 bg-green-600 text-white text-lg font-bold rounded-lg hover:bg-green-700 transition-transform transform hover:scale-105"
            onClick={() => handleSaveClick("sponsor")}
          >
            Save Sponsor Voting
          </button>
        </div>

          {/* Audience Voting Weight */}
          <div>
          <h2 className="text-3xl font-semibold text-gray-800">Audience Voting Weight</h2>
        </div>

        <div className="space-y-6">
          {/* Likes */}
          <div className="flex flex-col">
            <label htmlFor="like" className="text-lg font-semibold text-gray-600">1 Like:</label>
            <input
              type="number"
              id="like"
              name="likes"
              className="mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              value={formData.audience.likes}
              onChange={(e) => handleInputChange("audience", e)}
            />
          </div>

          {/* Comments */}
          <div className="flex flex-col">
            <label htmlFor="comment" className="text-lg font-semibold text-gray-600">1 Comment:</label>
            <input
              type="number"
              id="comment"
              name="comments"
              className="mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              value={formData.audience.comments}
              onChange={(e) => handleInputChange("audience", e)}
            />
          </div>

          {/* Shares */}
          <div className="flex flex-col">
            <label htmlFor="share" className="text-lg font-semibold text-gray-600">1 View:</label>
            <input
              type="number"
              id="share"
              name="shares"
              className="mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              value={formData.audience.shares}
              onChange={(e) => handleInputChange("audience", e)}
            />
          </div>

          <button
            type="button"
            className="w-full mt-4 py-2 px-4 bg-blue-600 text-white text-lg font-bold rounded-lg hover:bg-blue-700 transition-transform transform hover:scale-105"
            onClick={() => handleSaveClick("audience")}
          >
            Save Audience Voting
          </button>
        </div>

      </form>
    </div>
  );
};

export default VotingSetting;
