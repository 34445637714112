import React from "react";
import styles from "./Post.module.css";
import { participantData } from "../participantData";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../backIcon.png";

const Posts = () => {
  const navigate = useNavigate();
  const { participantId } = useParams();

  const participant = participantData.find(
    (p) => p.id === parseInt(participantId)
  );

  if (!participant) {
    return <div>Participant not found</div>;
  }

  const navigateToParticipantDetail = () => {
    navigate(`/participants/${participantId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button
          type="button"
          className={styles.backButton}
          onClick={navigateToParticipantDetail}
        >
          <img src={backIcon} alt="backIcon" />
        </button>
        <h2 className={styles.title}>All Posts</h2>
      </div>
      <div className={styles.posts}>
        {participant.posts.map((post) => (
          <div key={post.id} className={styles.card}>
            <img
              src={post.imageUrl}
              alt={`Post ${post.id}`}
              className={styles.image}
            />
            <div className={styles.details}>
              <span className={styles.dateTime}>
                {post.date} {post.time}
              </span>
              <span className={styles.rank}>Rank: {post.rank}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Posts;
