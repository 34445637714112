import React from "react";
import AddCategory from "./AddCategory/AddCategory";
import CategoryTable from "./CategoryTable/CategoryTable";

const Category = () => {
  return (
    <div className="w-[76vw] ml-[20vw]">
      <AddCategory />
      <CategoryTable />
    </div>
  );
};

export default Category;
