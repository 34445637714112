import React from 'react';

export const Terms = () => {
  const termsData = {
    lastUpdated: "September 6, 2024",
    sections: [
      {
        title: "Introduction",
        content: `Welcome to Namasvi ("we," "our," "us"). These Terms and Conditions ("Terms") govern your use of our mobile application (the "App") and related services. By accessing or using the App, you agree to comply with and be bound by these Terms.`
      },
      {
        title: "Eligibility",
        content: `You must be at least 18 years old to use the App. By using the App, you represent and warrant that you meet this age requirement.`
      },
      {
        title: "Account Registration",
        content: `Accurate Information: You agree to provide accurate, current, and complete information during registration and to update it as necessary.\nSecurity: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.\nTermination: We reserve the right to suspend or terminate your account for any violation of these Terms.`
      },
      {
        title: "Use of the App",
        content: `a. License Grant: We grant you a limited, non-exclusive, non-transferable, revocable license to use the App in accordance with these Terms.\nb. Prohibited Activities: You agree not to:\n- Use the App for any unlawful purpose.\n- Attempt to gain unauthorized access to the App’s systems or networks.\n- Distribute malware or other harmful software.\n- Engage in any activity that interferes with or disrupts the App’s functionality.`
      },
      {
        title: "User Content",
        content: `Ownership: You retain ownership of any content you upload or share through the App.\nLicense: By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and display your content in connection with the App’s functionality.\nResponsibility: You are responsible for the content you post and must ensure it does not infringe on the rights of others or violate any laws.`
      }
    ]
  };

  return (
    <div className='w-[76vw] ml-[20vw] mt-28 p-6'>
      <h1 className='text-5xl font-bold text-center mb-6'>Terms and Conditions</h1>
      <p className='text-lg text-gray-600 text-center mb-8'>
        Last Updated: {termsData.lastUpdated}
      </p>
      {termsData.sections.map((section, index) => (
        <div key={index} className='mb-8'>
          <h2 className='text-3xl font-semibold text-blue-800 mb-4'>{section.title}</h2>
          <p className='text-lg text-gray-700 whitespace-pre-line'>{section.content}</p>
        </div>
      ))}
    </div>
  );
};
