import React from "react";
// import { useNavigate } from "react-router-dom";
import styles from "./ParticipantDetails.module.css";
import { participantData } from "../participantData"; // Adjust the path as needed
import editIcon from "../../../assets/edit.png";
import delIcon from "../../../assets/del.png";
const ParticipantOngoingTable = () => {
  // const navigate = useNavigate();

  // Assuming there is only one ongoing event at a time, get its details
  const ongoingEvent = participantData.find(
    (participant) => participant.ongoing
  );

  if (!ongoingEvent) {
    return <p>No ongoing events found.</p>;
  }

  const {
    eventName,
    category,
    rank,
    startDate,
    endDate,
    venue,
    actions: { edit, delete: deleteFunction }, // Rename delete to deleteFunction
  } = ongoingEvent.ongoing;

  return (
    <div className={styles.eventsTable}>
      <h2 className={styles.tableHeader}>Ongoing</h2>
      <table className={styles.tableEvent}>
        <thead>
          <tr className={styles.eventTr}>
            <th>Event Name</th>
            <th>Category</th>
            <th>Rank</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Venue</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          <tr>
            <td>{eventName}</td>
            <td>{category}</td>
            <td>{rank}</td>
            <td>{startDate}</td>
            <td>{endDate}</td>
            <td>{venue}</td>
            <td>
              <button className={styles.editButton}>
                <img src={editIcon} slt="edit" />
              </button>
              <button className={styles.deleteButton}>
                <img src={delIcon} alt="delete" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ParticipantOngoingTable;
