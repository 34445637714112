export const donationData = [
  {
    donerName: "John Doe",
    category: "Education",
    amount: 100,
    eventName: "Charity Run 2024",
    paymentMode: "Credit Card",
    transactionId: "TXN12345678",
  },
  {
    donerName: "Jane Smith",
    category: "Health",
    amount: 250,
    eventName: "Health Awareness Drive",
    paymentMode: "PayPal",
    transactionId: "TXN87654321",
  },
  {
    donerName: "Alice Johnson",
    category: "Environment",
    amount: 75,
    eventName: "Tree Plantation Drive",
    paymentMode: "Debit Card",
    transactionId: "TXN11223344",
  },
  {
    donerName: "Bob Brown",
    category: "Animal Welfare",
    amount: 50,
    eventName: "Pet Adoption Fair",
    paymentMode: "Bank Transfer",
    transactionId: "TXN44332211",
  },
  {
    donerName: "Charlie Davis",
    category: "Community Development",
    amount: 150,
    eventName: "Community Cleanup",
    paymentMode: "Credit Card",
    transactionId: "TXN55667788",
  },
];
