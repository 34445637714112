export const roleData = [
  {
    roleName: "Coordinator",
    location: "New York",
    eventName: "Charity Run",
    assignedUsers: 10,
    donationsReceived: "$5,000",
    action: {
      edit: "Edit",
      delete: "Delete",
    },
  },
  {
    roleName: "Volunteer",
    location: "Los Angeles",
    eventName: "Food Drive",
    assignedUsers: 25,
    donationsReceived: "$3,000",
    action: {
      edit: "Edit",
      delete: "Delete",
    },
  },
  {
    roleName: "Manager",
    location: "Chicago",
    eventName: "Fundraising Gala",
    assignedUsers: 5,
    donationsReceived: "$10,000",
    action: {
      edit: "Edit",
      delete: "Delete",
    },
  },
  {
    roleName: "Coordinator",
    location: "Houston",
    eventName: "Blood Donation Camp",
    assignedUsers: 8,
    donationsReceived: "$2,000",
    action: {
      edit: "Edit",
      delete: "Delete",
    },
  },
  {
    roleName: "Organizer",
    location: "Miami",
    eventName: "Book Fair",
    assignedUsers: 15,
    donationsReceived: "$1,500",
    action: {
      edit: "Edit",
      delete: "Delete",
    },
  },
];
