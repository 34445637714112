import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./DonationSettings.module.css";
import backIcon from "../backIcon.png";
import plusIcon from "./plus.png"; // Import the plus icon

const DonationSettings = () => {
  const navigate = useNavigate();
  const [amountFields, setAmountFields] = useState([{ id: 1 }]);
  const [isCustomAmountAllowed, setIsCustomAmountAllowed] = useState(false); // State for toggle button

  const addAmountField = () => {
    setAmountFields([...amountFields, { id: amountFields.length + 1 }]);
  };

  const handleToggleClick = () => {
    setIsCustomAmountAllowed(!isCustomAmountAllowed);
  };

  return (
    <div className={styles.container}>
      <form className={styles.eventForm}>
        <div className={styles.header}>
          <button
            type="button"
            className={styles.backButton}
            onClick={() => navigate("/donations")}
          >
            <img src={backIcon} alt="backIcon" />
          </button>
          <h2 className={styles.title}>Payment Settings</h2>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="event">Event :</label>
            <select id="event" name="event">
              <option value="event1">Event 1</option>
              <option value="event2">Event 2</option>
              <option value="event3">Event 3</option>
            </select>
          </div>
        </div>

        {amountFields.map((field, index) => (
          <div className={styles.row} key={field.id}>
            <div className={styles.column}>
              {/* Only display the label for the first field */}
              {index === 0 && (
                <label htmlFor={`amount-${field.id}`}>Amount:</label>
              )}
              <input
                type="text"
                id={`amount-${field.id}`}
                name={`amount-${field.id}`}
                style={{ marginLeft: index === 0 ? 0 : "151px" }} // Align new input with the first one
              />
              {index === 0 && (
                <button
                  type="button"
                  className={styles.addButton}
                  onClick={addAmountField}
                >
                  <img src={plusIcon} alt="Add Amount" />
                </button>
              )}
            </div>
          </div>
        ))}

        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="payTo">Payment:</label>
            <select id="payTo" name="payTo" className={styles.fullWidth}>
              <option value="method1">Payment</option>
              <option value="method2">Donation</option>
            </select>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="payMethod">Payment Methods:</label>
            <select
              id="payMethod"
              name="payMethod"
              className={styles.fullWidth}
            >
              <option value="method1">Method 1</option>
              <option value="method2">Method 2</option>
              <option value="method3">Method 3</option>
            </select>
          </div>
        </div>

        {/* Toggle Button for Custom Amount */}
        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="customAmountToggle">
              Is custom amount allowed?
            </label>
            <button
              type="button"
              id="customAmountToggle"
              className={`${styles.toggleButton} ${
                isCustomAmountAllowed ? styles.active : ""
              }`}
              onClick={handleToggleClick}
            >
              {isCustomAmountAllowed ? "Yes" : "No"}
            </button>
          </div>
        </div>

        <button
          type="button"
          className={styles.viewButton}
          onClick={() => navigate("")}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default DonationSettings;
