import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../Participants/participantTable/ParticipantTable.module.css";
import { DataContext } from "../../../context/DataContext";
import axios from "axios";
import Cookies from "js-cookie";
import SponsorDetails from "../sponsorDetails/SponsorDetails";

const SponsorDetail = () => {
  const { eventData, sponsors, loading } = useContext(DataContext);
  if(loading) <></>

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All");
  const [selectedEvent, setSelectedEvent] = useState("All");

  const [participants, setParticipants] = useState([]);
  const [prevURL, setPrevURL] = useState("");
  const [nextURL, setNextURL] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for Modal visibility
  const [passphrase, setPassphrase] = useState(""); // State for Passphrase input
  const [selectedParticipant, setSelectedParticipant] = useState(null); // State for Selected Participant
  const navigate = useNavigate();
  
  const [totalParticipants, setTotalParticipants] = useState(null);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const accessToken = Cookies.get("fbauthToken");

  useEffect(() => {
    if(loading){
      return
    }
    if(selectedEvent && selectedEvent != "All"){
      const validEventIds = eventData
      .filter(event => event.eventID === selectedEvent.eventID) // Filter by specific event name
      .map(event => event.eventID); // Extract eventIds
      console.log("Ids", validEventIds);
      console.log("selected event", selectedEvent);
      console.log("selected event", participants);
      const filteredParticipants = totalParticipants.filter(participant =>
        participant.eventCategories[0].some((category) => 
          validEventIds.includes(category.eventId)
        )
      );
      console.log("filtered parts",filteredParticipants);
      setParticipants(filteredParticipants)
    }else{
      if(sponsors){
        console.log("sponsor detail", sponsors)
        setParticipants(sponsors.sponsors); 
        setTotalParticipants(sponsors.sponsors);
        return;
      }
    }
  }, [selectedEvent, currentPage, pageSize, loading]);

  const handleFilterChange = (event) => {
    const eventName = event.target.value;
    if(eventName == "All")
    {
      setFilter(eventName);
      setCurrentPage(1);
      console.log("part", sponsors)
      setParticipants(sponsors.sponsors);
      return
    }
    setFilter(eventName);
    setCurrentPage(1); // Reset to first page on filter change
    const selected = eventData.find((e) => e.eventName === eventName);
    setSelectedEvent(selected || null);
  };

  const handlePagination = (direction) => {
    if (direction === "prev" && prevURL) {
      const params = new URLSearchParams(prevURL.split("?")[1]);
      setCurrentPage(parseInt(params.get("page"), 10));
    }
    if (direction === "next" && nextURL) {
      const params = new URLSearchParams(nextURL.split("?")[1]);
      setCurrentPage(parseInt(params.get("page"), 10));
    }
  };

  const handleRowClick = (sponsor) => {
    setSelectedParticipant(sponsor);
    setIsModalOpen(true); // Open the modal
  };

  const handlePassphraseSubmit = () => {
    if (passphrase.trim() !== "") {
      console.log(
        "selctted insta",
        selectedParticipant.instagramBusinessAccountId
      );
      navigate(
        `/sponsors/${selectedParticipant.user_id}/${selectedParticipant.ig_user.id}?username=${Cookies.get("username")}&passphrase=${passphrase}`, 
        {
          state: {
            // Add the properties you want to pass in the location.state here
            sponsor: selectedParticipant, // Replace this with your desired state properties
          }
        }
      );
      

      setIsModalOpen(false); // Close the modal
      setPassphrase(""); // Clear the passphrase input
    }
  };

  return (
    <>
      <div className={styles.filterCreateContainer}>
        <div>
          <select
            className={styles.filterButton}
            value={filter}
            onChange={handleFilterChange}
          >
            {eventData &&
               <option key={"All"} value={"All"}>
               {"All"}
             </option>}
            {eventData &&
              eventData.map((x) => (
                <option key={x.eventName} value={x.eventName}>
                  {x.eventName}
                </option>
              ))}
          </select>
        </div>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search..."
            className={styles.searchInput}
          />
        </div>
      </div>
      <div>
        <div className={styles.eventsTable}>
          <table className={styles.tableEvent}>
            <thead>
              <tr className={styles.eventTr}>
                <th>Profile Pic.</th>
                <th>Insta User Name</th>
                <th>Followers</th>
                <th>Name</th>
                <th>Events Sponsored</th>
              </tr>
            </thead>
            <tbody>
              {participants &&
                participants.map((part, index) => (
                  <tr key={index} onClick={() => handleRowClick(part)}>
                    <td>
                      <img
                        src={part.ig_user.profile_picture_url}
                        alt="/profile-pic"
                        className="w-12 h-12 rounded-full ml-8"
                      />
                    </td>
                    <td>{part.ig_user.username}</td>
                    <td>{part.ig_user.followers_count}</td>
                    <td>{part.ig_user.name}</td>
                    <td>{part.eventsSponsored}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className={styles.pagination}>
          <button
            onClick={() => handlePagination("prev")}
            disabled={!prevURL}
            className={styles.paginationButton}
          >
            Prev
          </button>
          <p className={styles.pageText}>Page {currentPage}</p>
          <button
            onClick={() => handlePagination("next")}
            disabled={!nextURL}
            className={styles.paginationButton}
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Enter Passphrase</h3>
            <input
              type="text"
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              placeholder="Passphrase"
              className={styles.modalInput}
            />
            <button
              onClick={handlePassphraseSubmit}
              className={styles.okButton}
            >
              OK
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className={styles.cancelButton}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SponsorDetail;
