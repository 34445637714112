import React, { useState } from "react";
import { handleLogin } from "./loginHandelSystem/hadelFunction";
// import { useParams } from "react-router-dom";
import Cookies from 'js-cookie'

export const Login = () => {
  const [username, setUsername] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  // Set random values for authToken, loginTime, and fbauthToken
// Cookies.set("authToken", );
// Cookies.set("loginTime", Date.now());
// Cookies.set('fbauthToken', "EAAO5JZBORQjIBOxoetQIjgkDf2mcci8qshYMfEMpVM2pgVjgh6Lb4iPN2a7bJlRgQEHsMMwZCVGXpolGwv4SEcqFKJGAccRs8NlTGvsCm30lBdvnOKwbxRa20ADpIzmPB7jedmeLJDdZABb4htjjbvtxmgesiIfoZBdRHUQqngadqZCSk6FMX2ZB3w1rSQq1K2Dl2EIVolJRFCBjB87uctz6c8");
// // Cookies.remove("authToken", );
// Cookies.remove("loginTime", Date.now());
// Cookies.remove('fbauthToken', "EAAMNMF5aKJwBOZC0Sg7GJVgSDBa7HOA7Tz6uuPVp7qKiI0s8B2h4oF10RAyYd5UuTvEZCwOZBMQZBLzzTfuQpKYiEpZBOLIocTKH81CC3KdlKwZCgR9EqeYxvd3qPS5pzdB3U7OOYbyGdz6D1Y2ozZAKURzA4a9KVwxYaMDzsX1nR970DbPkTFNZBgY2WUsOftEkN3lMkHEK");
  if(Cookies.get("authToken") && Cookies.get("loginTime") && !Cookies.get('fbauthToken')){
    window.location.href = `${apiDomain}/auth/facebook?redirectTo=https://namasvi.indicarena.com/auth/facebook/callback`;
    // window.location.href = 'https://dafukcrew.com/auth/facebook?redirectTo=https://namasvinexus.com/auth/facebook/callback';
    // window.location.href = 'https://dafukcrew.com/auth/facebook?redirectTo=https://localhost:3000/auth/facebook/callback';
    return;
}
if(Cookies.get("authToken") && Cookies.get("loginTime") && Cookies.get('fbauthToken')){
  window.location.href = '/';
  return;
}
  const onLoginClick = () => {
    // Trim the input values before sending
    const trimmedUsername = username.trim();
    const trimmedPassphrase = passphrase.trim();

    // Call the handleLogin function with the trimmed inputs
    handleLogin(trimmedUsername, trimmedPassphrase);
  };


  return (
    <div className="w-full bg-white ml-[20vw] mt-20 p-16 items-center justify-center">
      <main className="w-full flex flex-col items-start space-y-4">
        <div className="flex items-center gap-5 mb-2">
          <p className="text-xl font-semibold">Login:</p>
          <input
            type="text"
            placeholder="Enter your username..."
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border rounded-md px-4 py-2"
          />
        </div>
        <div className="flex items-center gap-5 mb-2">
        <p className="text-xl font-semibold">Passphrase:</p>
          <input
            type="password"
            placeholder="Enter your passphrase..."
            value={passphrase}
            onChange={(e) => setPassphrase(e.target.value)}
            className="border rounded-md px-4 py-2"
          />
        </div>
        <button 
          className="text-2xl border-2 bg-gray-800 text-white px-4 py-1"
          onClick={onLoginClick}
        
        >
          Login
        </button>
        
      </main>
    </div>
  );
};
