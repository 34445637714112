import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./EventTable.module.css";
import { DataContext } from "../../../context/DataContext";
import EventCard from "./EventCard";


const EventTable = ({ eventData, Dashboard }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All");
  const eventsPerPage = 4;
  const [dashboard, setDashboard] = useState(Dashboard);
  const navigate = useNavigate();
 

  useEffect(() => {
    setDashboard(Dashboard);
  }, [Dashboard]);

  // if (loading)  <></>;

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;

  const currentEvents = eventData
    ? eventData
    : []
        // console.log("currentEvents", currentEvents)
        .filter((event) =>
          filter === "All" ? true : event.category === filter
        )
        .slice(indexOfFirstEvent, indexOfLastEvent);
  console.log("currentDataEvent", currentEvents);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1);
  };



  return (
    <>
      <div className={styles.filterCreateContainer}>
        <div>
          {!dashboard && (
            <button
              className={styles.createButton}
              onClick={() => navigate("/event-form")}
            >
              + Create
            </button>
          )}
        </div>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search..."
            className={styles.searchInput}
          />
        </div>
      </div>
    
      {eventData && eventData.map(x => <EventCard event={x} />)}
    </>
  );
};

export default EventTable;
