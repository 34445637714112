import React, { useContext } from "react";
import TotalStat from "./TotalStat";
import EventTable from "../events/eventTable/EventTable";
import { DataContext } from "../../context/DataContext";
import Cookies from 'js-cookie'

const Dashboard = () => {
  const {eventData, loading} = useContext(DataContext)
  
  if(loading) return<></>
  // console.log("USERID", Cookies.get("userId"))
  return (
    <div className="w-[75vw] ml-[20vw]">
      <TotalStat />
      <EventTable eventData={eventData} Dashboard = {true}/>
    </div>
  );
};

export default Dashboard;
