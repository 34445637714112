import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sponsorData } from "../sponsorData";
import styles from "./SponsorDetails.module.css";
import backIcon from "../backIcon.png";

const SponsorProfile = () => {
  const navigate = useNavigate();
  const { participantId } = useParams();
  const participant = sponsorData.find((p) => p.id === parseInt(participantId));

  if (!participant) {
    return <div>Sponsor not found</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button
          type="button"
          className={styles.backButton}
          onClick={() => navigate("/participants")}
        >
          <img src={backIcon} alt="backIcon" />
        </button>
        <h2 className={styles.title}>{participant.name}</h2>
      </div>
      <div className={styles.profileContainer}>
        <div className={styles.profileInfo}>
          <img
            src={participant.profilePic}
            alt="Profile"
            className={styles.profilePic}
          />
          <div className={styles.textInfo}>
            <h3 className={styles.username}>{participant.username}</h3>
            <p className={styles.category}>{participant.category}</p>
          </div>
        </div>
        {/* <div className={styles.buttonContainer}>
          <button className={styles.buttonViewPost} onClick={handleViewPost}>
            View Post
          </button>
          <button
            className={styles.buttonPreference}
            onClick={handleSeePreferences}
          >
            See Preferences
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default SponsorProfile;
